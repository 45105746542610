import { Heading } from '@/components/heading';
import { useConan } from '@golee/gle-conan-tracker';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigateBack } from '../../../../../components';
import { Parent, useOrgPerson } from '../../../../../context/org-person';
import { notifyDataChange } from '../../utils/notify-data-changed';
import { ParentsForm } from './parent-form';

export const ProfileParentsPage = () => {
	const navigate = useNavigate();
	const { trackEvent } = useConan();
	const { orgPerson, refreshParents } = useOrgPerson();

	const { state } = useLocation();

	const parent = (state as { parent?: Parent })?.parent;

	const notifyChangesToOrganization = () => {
		notifyDataChange(orgPerson, ['parents']);
		void trackEvent('family.orgPerson-changed-data-from-app', {
			values: 'parents',
			section: 'name_surname',
		});
	};

	const initLayout = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		initLayout();
	}, []);

	return (
		<div className="pb-4">
			<NavigateBack to={'/profile'} className="mb-4" />

			<Heading level={2} className="mb-4">
				{parent ? 'Modifica responsabile legale' : 'Aggiungi responsabile legale'}
			</Heading>

			<ParentsForm
				orgPersonId={orgPerson.orgPersonId}
				season={orgPerson.season}
				isEdit={!parent}
				initialValues={{
					_id: parent?._id,
					name: parent?.name || '',
					surname: parent?.surname,
					fiscal_code: parent?.fiscal_code,
					birthdate: parent?.birthdate ? new Date(parent.birthdate) : undefined,
					birthplace: parent?.birthplace,
					kinship: parent?.kinship,
					address: {
						address: parent?.address?.address || undefined,
						civic_number: parent?.address?.civic_number || undefined,
						cap: parent?.address?.cap || undefined,
						town: parent?.address?.town,
						province: parent?.address?.province,
						region: parent?.address?.region,
					},
					recap: {
						email: parent?.recap?.email || undefined,
						phone: parent?.recap?.email || undefined,
					},
				}}
				onComplete={() => {
					notifyChangesToOrganization();
					refreshParents();
					navigate('/profile');
				}}
				onCancel={() => {
					navigate('/profile');
				}}
			/>
		</div>
	);
};
