import { useEffect, useState } from 'react';
import { apiClient } from '../api/client';
import { useCache } from '../context/cache';

export type CustomOption = {
	label: string;
	value: string;
	organizationId?: string;
};

const loadOptions = (key: string) => apiClient('club').get<CustomOption[]>(`custom-options/${key}`);

export const useCustomSelect = (key: string) => {
	const cache = useCache<CustomOption[]>();
	const [options, setOptions] = useState<CustomOption[]>([]);

	const load = async () => {
		const cachedOptions = cache.get(key);

		if (cachedOptions) {
			setOptions(cachedOptions);
			return;
		}

		try {
			const { data } = await loadOptions(key);

			const mapped = data.map(option => ({
				label: option.label,
				value: option.value,
				isCreatedOption: !!option.organizationId,
			}));

			cache.set(key, mapped);
			setOptions(mapped);
		} catch (err) {}
	};

	useEffect(() => {
		load();
	}, []);

	return { options };
};
