import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { ExternalLinkIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { DocumentModel, getDocuments } from '../../../../../api/documents/documents';

export const PaymentAttachments = ({ paymentId }: { paymentId: string }) => {
	const [documents, setDocuments] = useState<DocumentModel[]>([]);

	const loadDocuments = async () => {
		if (!paymentId) return;
		try {
			const { data } = await getDocuments('operation', paymentId);
			setDocuments(data);
		} catch (err) {}
	};

	useEffect(() => {
		loadDocuments();
	}, []);

	if (!documents.length) {
		return <></>;
	}

	return (
		<div className="mt-5 border-t border-solid border-zinc-100 pt-5">
			<Heading level={3} className="mb-2">
				Allegati
			</Heading>

			<div className={'flex flex-wrap gap-2'}>
				{documents.map(doc => (
					<a key={doc._id} href={doc.url} target={'_blank'}>
						<Button variant={'secondary'} size={'sm'}>
							{doc.name} <ExternalLinkIcon size={18} className="ml-2" />
						</Button>
					</a>
				))}
			</div>
		</div>
	);
};
