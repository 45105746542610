import { Badge } from '@/components/badge';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { DocumentModel } from '../../../../api/documents/documents';
import { MimeTypeIcon } from '../mime-type-icon';

export const DocumentCard = ({ document }: { document: DocumentModel }) => {
	return (
		<Link
			className="border-b border-solid border-slate-100 p-4 transition-colors hover:cursor-pointer hover:bg-slate-50"
			to={`/documents/${document._id}`}
			state={{ document }}>
			<div className="mb-1 flex justify-end gap-1">
				{document.type_entity === 'org_person' && !document.seenFromOrgPerson && (
					<Badge variant={'secondary'}>Nuovo</Badge>
				)}

				{document.signature?.status === 'pending' && <Badge variant={'warning'}>Da firmare</Badge>}

				{document.signature?.status === 'completed' && <Badge variant={'success'}>Firmato</Badge>}
			</div>

			<div className={'mt-1 flex items-center gap-5'}>
				<MimeTypeIcon mimeType={document.mimeType} />

				<div className="flex flex-col gap-1">
					<p className="text-sm">{document.name}</p>

					<p className="text-xs text-gray-400">
						{document.createdAt ? DateTime.fromISO(document.createdAt).toFormat('HH:mm dd/MM/yyyy') : ''}
					</p>
				</div>
			</div>
		</Link>
	);
};
