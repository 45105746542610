import { readAsArrayBuffer } from './async-reader';
import { getAsset } from './prepare-assets';

export async function saveAsBase64(pdfFile: File, objects: any[]) {
	const PDFLib = await getAsset('PDFLib');

	let pdfDoc: any;

	try {
		pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdfFile));
	} catch (e) {
		console.log('Failed to load PDF.');
		throw e;
	}

	const pagesProcesses = pdfDoc.getPages().map(async (page: any, pageIndex: number) => {
		const pageObjects = objects[pageIndex];
		// 'y' starts from bottom in PDFLib, use this to calculate y
		const pageHeight = page.getHeight();

		const embedProcesses = pageObjects.map(async (object: any) => {
			if (object.type === 'image') {
				let { file, x, y, width, height } = object;

				let img: any;
				try {
					if (file.type === 'image/jpeg') {
						img = await pdfDoc.embedJpg(await readAsArrayBuffer(file));
					} else {
						img = await pdfDoc.embedPng(await readAsArrayBuffer(file));
					}

					return () =>
						page.drawImage(img, {
							x,
							y: pageHeight - y - height,
							width,
							height,
						});
				} catch (e) {
					console.log('Failed to embed image.', e);
					return () => {};
				}
			}
		});

		// embed objects in order
		const drawProcesses = await Promise.all(embedProcesses);
		drawProcesses.forEach(p => p());
	});

	await Promise.all(pagesProcesses);

	try {
		const pdfAsBase64 = await pdfDoc.saveAsBase64();
		return pdfAsBase64;
	} catch (e) {
		console.log('Failed to save PDF.');
		throw e;
	}
}
