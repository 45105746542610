import { Heading } from '@/components/heading';
import { useConan } from '@golee/gle-conan-tracker';
import { useNavigate } from 'react-router-dom';
import { NavigateBack } from '../../../../../components';
import { useOrgPerson } from '../../../../../context/org-person';
import { notifyDataChange } from '../../utils/notify-data-changed';
import { MedicalForm } from './medical-form';

export const ProfileMedicalPage = () => {
	const navigate = useNavigate();
	const { trackEvent } = useConan();
	const { orgPerson, refresh: refreshOrgPersonContext } = useOrgPerson();

	const notifyChangesToOrganization = (updatedValues: { [key: string]: boolean | undefined }) => {
		const updatedValuesAsArray = Object.keys(updatedValues).filter(key => !!updatedValues[key]);

		if (updatedValuesAsArray.length) {
			notifyDataChange(orgPerson, updatedValuesAsArray as string[]);
			void trackEvent('family.orgPerson-changed-data-from-app', {
				values: updatedValuesAsArray.join(', '),
				section: 'name_surname',
			});
		}
	};

	return (
		<div className="pb-4">
			<NavigateBack to={'/profile'} className="mb-4" />
			<Heading level={2} className="mb-4">
				Profilo sanitario
			</Heading>

			<MedicalForm
				orgPersonId={orgPerson.orgPersonId}
				season={orgPerson.season}
				initialValues={{
					allergies: orgPerson.personMetadata.medicalProfile?.allergies,
					healthCardNumber: orgPerson.personMetadata.medicalProfile?.healthCardNumber,
					medicalExaminationExpirationDate: orgPerson.personMetadata.dates?.medicalExaminationExpirationDate
						? new Date(orgPerson.personMetadata.dates.medicalExaminationExpirationDate)
						: undefined,
					medicalExaminationScheduledDate: orgPerson.personMetadata.dates?.medicalExaminationScheduledDate
						? new Date(orgPerson.personMetadata.dates.medicalExaminationScheduledDate)
						: undefined,
				}}
				onCancel={() => {
					navigate('/profile');
				}}
				onComplete={updatedValues => {
					notifyChangesToOrganization(updatedValues);
					refreshOrgPersonContext();
					navigate('/profile');
				}}
			/>
		</div>
	);
};
