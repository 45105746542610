export const preloadAssets = () => {
  const pictures: string[] = [
    `apple-pay.png`,
    `google-pay.png`,
    `visa.png`,
    `mastercard.png`,
    `sepa.png`,
    `error_icon.png`,
  ];
  pictures.forEach((picture) => {
    const img = new Image();
    img.src = `${window._env_.PUBLIC_URL}images/${picture}`;
  });
};
