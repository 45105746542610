
type Base = {
	readOnly?: boolean;
	disabled?: boolean;
	isClearable?: boolean;
};

type FieldType = 'input-date' | 'custom-select' | 'input' | 'checkbox' | 'select';

export const useUpdatedFieldProps = <T extends Base>(props: T, fieldType: FieldType) => {

	return props;
};
