import Emoji from 'a11y-react-emoji';
import { ReasonWrapper } from './reason-wrapper';

const NotGoingNotes = ({ onClick, selectedReason }: { onClick: (name: string) => void; selectedReason?: string }) => {
	return (
		<div className="grid items-stretch gap-5 grid-cols-2 grid-row-1">
			<ReasonWrapper onClick={() => onClick('Malattia')} isActive={selectedReason === 'Malattia'}>
				<Emoji symbol="🤒" label="sick" style={{ fontSize: 30 }} />
				<p className="text-gray-500 text-sm">Malattia</p>
			</ReasonWrapper>
			<ReasonWrapper onClick={() => onClick('Infortunio')} isActive={selectedReason === 'Infortunio'}>
				<Emoji symbol="🤕" label="injured" style={{ fontSize: 30 }} />
				<p className="text-gray-500 text-sm">Infortunio</p>
			</ReasonWrapper>
			<ReasonWrapper onClick={() => onClick('Vacanza')} isActive={selectedReason === 'Vacanza'}>
				<Emoji symbol="🏖" label="holiday" style={{ fontSize: 30 }} />
				<p className="text-gray-500 text-sm">Vacanza</p>
			</ReasonWrapper>
			<ReasonWrapper onClick={() => onClick('')} isActive={false}>
				<Emoji symbol="📝" label="other" style={{ fontSize: 30 }} />
				<p className="text-gray-500 text-sm">Altro</p>
			</ReasonWrapper>
		</div>
	);
};

export default NotGoingNotes;
