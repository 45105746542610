import { useState } from 'react';

/**
 * Hook to handle modal state.
 */
export const useModal = () => {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

	const openModal = () => {
		setIsModalVisible(true);
	};

	const closeModal = () => {
		setIsModalVisible(false);
	};

	const toggleModal = () => {
		const visible = !!isModalVisible;
		setIsModalVisible(!visible);
	};

	return {
		openModal,
		closeModal,
		toggleModal,
		isModalVisible,
	};
};
