import { Label } from '@/components/label';
import { useI18n } from '@gle/providers.i18n';
import { FormikContextType } from 'formik';
import { Metadata } from '../../api/people/metadata';
import { CustomField } from '../../hooks/custom-input-fields/types';
import { CustomInputField } from './custom-input-field';
import { CustomValueField } from './custom-value-field';

export const CustomInputFields = (props: {
	fields: CustomField[];
	formContext: FormikContextType<Metadata>;
	isEditing: boolean;
}) => {
	const { messages } = useI18n();

	return (
		<div>
			{props.fields.map(field => {
				//TODO: smell. We have to remove metadata. cause we are calling PATCH on metadata ad it is included
				const fieldKey = field.key.replace('metadata.', '');

				return (
					<div className="mb-2">
						{props.isEditing ? (
							<>
								{field.isEditableFromOrgPerson ? (
									<div>
										<Label className="mb-1.5 block text-muted-foreground">
											{messages[field.labelKey]}
										</Label>
										<CustomInputField
											hideLabel
											field={{ ...field }}
											value={getValueByDotNotation(props.formContext.values, fieldKey)}
											onChange={value => {
												props.formContext.setFieldValue(fieldKey, value);
											}}
										/>
									</div>
								) : (
									<></>
								)}
							</>
						) : (
							<div>
								<Label className="text-muted-foreground">{messages[field.labelKey]}</Label>
								<div className="mt-1.5 min-h-10 rounded-md border border-solid border-muted px-3 py-2">
									<CustomValueField
										field={field}
										value={getValueByDotNotation(props.formContext.values, fieldKey)}
									/>
								</div>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

const getValueByDotNotation = (obj: any, dotNotation: string): any => {
	return dotNotation.split('.').reduce((acc, key) => acc?.[key], obj);
};
