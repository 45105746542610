import { Button } from '@/components/button';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from '@/components/drawer';
import { PlusIcon } from 'lucide-react';
import { useState } from 'react';
import { SignatureCanvasUploader } from './signature-canvas-uploader';

type AddSignatureButtonProps = {
	onSignCanceled?: () => void;
	onSignAdded?: (file: File) => void;
	disabled: boolean;
	label: string;
};

export const AddSignatureButton = (props: AddSignatureButtonProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<Drawer open={isOpen} onOpenChange={setIsOpen}>
			<DrawerTrigger>
				<Button disabled={props.disabled} variant={'outline'}>
					<PlusIcon className="mr-2 h-4 w-4" />
					{props.label}
				</Button>
			</DrawerTrigger>
			<DrawerContent>
				<DrawerHeader>
					<DrawerTitle>Inserisci la tua firma qui</DrawerTitle>
				</DrawerHeader>

				<div data-vaul-no-drag className="p-4">
					<SignatureCanvasUploader
						onCancel={() => {
							props.onSignCanceled?.();
							setIsOpen(false);
						}}
						onChange={file => {
							if (file) {
								props.onSignAdded?.(file);
							}
							setIsOpen(false);
						}}
					/>
				</div>
			</DrawerContent>
		</Drawer>
	);
};
