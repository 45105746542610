import axios from 'axios';

export const validateFiscalCode = async (fiscalCode?: string) => {
	if (!fiscalCode || fiscalCode.length !== 16) return false;

	try {
		const { data } = await axios.post<{ err?: string }>(window._env_.REACT_APP_FISCAL_CODE_SERVICE_API, {
			data: fiscalCode,
		});

		if (data.err) throw new Error();

		return true;
	} catch (err) {
		return false;
	}
};
