import axios from 'axios';
import { getAsset } from './prepare-assets';

export function readAsArrayBuffer(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsArrayBuffer(file);
	});
}

export function readAsImage(src: string | Blob): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(img);
		img.onerror = reject;
		if (src instanceof Blob) {
			const url = window.URL.createObjectURL(src);
			img.src = url;
		} else {
			img.src = src;
		}
	});
}

export function readAsDataURL(file: File): Promise<string | ArrayBuffer | null> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(file);
	});
}

export async function readAsPDF(file: File) {
	const pdfjsLib = await getAsset('pdfjsLib');
	// Safari possibly get webkitblobresource error 1 when using origin file blob
	const blob = new Blob([file]);
	const url = window.URL.createObjectURL(blob);
	return pdfjsLib.getDocument(url).promise;
}

export async function readPDFfromURL(url: string) {
	const pdfjsLib = await getAsset('pdfjsLib');

	try {
		const response = await axios.get(url, {
			responseType: 'arraybuffer',
			headers: { 'Content-Type': 'application/pdf' },
		});

		const arrayBuffer = response.data;
		const pdfData = new Uint8Array(arrayBuffer);

		const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;

		const blob = new Blob([pdfData], { type: 'application/pdf' });

		const fileName = 'file.pdf';
		const file = new File([blob], fileName, { type: 'application/pdf' });

		return { pdf, file };
	} catch (error) {
		console.error('Error fetching PDF:', error);
		throw error;
	}
}
