import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import locations from './location.json';

export const LocationsContext = createContext<any | undefined>(undefined);

export const LocationsProvider = (props: { children: ReactNode }) => {
	const [regions, setRegions] = useState<any>();
	const [provinces, setProvinces] = useState<any>();
	const [countries, setCountries] = useState<any>();

	const getItalianRegions = async () => {
		setRegions(locations.regions);
	};

	const getItalianProvinces = () => {
		setProvinces(locations.provinces);
	};

	const getCountries = () => {
		setCountries(locations.countries);
	};

	useEffect(() => {
		getItalianRegions();
		getItalianProvinces();
		getCountries();
	}, []);

	return (
		<LocationsContext.Provider
			value={{
				regions,
				provinces,
				countries,
			}}>
			{props.children}
		</LocationsContext.Provider>
	);
};

export const useLocations = () => {
	const context = useContext(LocationsContext);

	if (!context) {
		throw new Error('useTranches must be used within TrancheProvider');
	}

	return context;
};
