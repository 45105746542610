import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Text } from '@gle/base-ui.typography.text';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
	const navigate = useNavigate();

	return (
		<div>
			<div className="text-center">
				<Heading className="mb-5">Oops!</Heading>

				<Text>La pagina che stai cercando non esiste.</Text>

				<Button onClick={() => navigate('/home')} className="mt-5" variant={'secondary'}>
					Torna alla home page
				</Button>
			</div>
		</div>
	);
};
