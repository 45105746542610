import { Card } from '@/components/card';
import { Link } from 'react-router-dom';
import { getDocumentsCount } from '../../../api/documents/documents';
import { AgreementsImage, CalendarImg, DocsImg, PaymentsImage, PostsImage, ProfileImage } from '../../../components';
import { Counter } from '../../../components/counter';
import { PermissionWrapper } from '../../../components/permission-wrapper/permission-wrapper';
import { OrgPerson } from '../../../context/org-person/types';
import { useTranches } from '../../../context/tranches';
import { useApi } from '../../../hooks/use-api';
import { getUnsetEventsByParticipant } from '../calendar/api';

export const CardsList = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	const { trancheToCashCount } = useTranches();

	const { data: docsCount = 0 } = useApi<number>(getDocumentsCount, {
		ref_entity: orgPerson.orgPersonId,
		seenFromOrgPerson: false,
	});

	const { data: eventsCount } = useApi<number>(getUnsetEventsByParticipant, {
		participantId: orgPerson.orgPersonId,
	});

	return (
		<div className="hide-scrollbar relative grid grid-cols-2 justify-center gap-5 pt-5">
			<Link to="/profile">
				<Card className="relative flex cursor-pointer flex-col items-center p-3 transition-colors hover:bg-slate-50">
					<ProfileImage />
					<p className="font-medium">Profilo</p>
				</Card>
			</Link>
			<Link to="/payments">
				<Card className="relative flex cursor-pointer flex-col items-center p-3 transition-colors hover:bg-slate-50">
					{trancheToCashCount > 0 && <Counter>{trancheToCashCount}</Counter>}
					<PaymentsImage />
					<p className="font-medium">Pagamenti</p>
				</Card>
			</Link>
			<Link to="/documents">
				<Card className="relative flex cursor-pointer flex-col items-center p-3 transition-colors hover:bg-slate-50">
					{docsCount > 0 && <Counter>{docsCount}</Counter>}
					<DocsImg />
					<p className="font-medium">Documenti</p>
				</Card>
			</Link>
			<Link to="/calendar">
				<Card className="relative flex cursor-pointer flex-col items-center p-3 transition-colors hover:bg-slate-50">
					{eventsCount > 0 && <Counter>{eventsCount}</Counter>}
					<CalendarImg />
					<p className="font-medium">Calendario</p>
				</Card>
			</Link>
			<PermissionWrapper permission="feed:read">
				<Link to="/posts">
					<Card className="relative flex cursor-pointer flex-col items-center p-3 transition-colors hover:bg-slate-50">
						<PostsImage />
						<p className="font-medium">Bacheca</p>
					</Card>
				</Link>
			</PermissionWrapper>
			<PermissionWrapper permission="agreements:read">
				<Link to="/agreements">
					<Card className="relative flex cursor-pointer flex-col items-center p-3 transition-colors hover:bg-slate-50">
						<AgreementsImage />
						<p className="font-medium">Consensi</p>
					</Card>
				</Link>
			</PermissionWrapper>
		</div>
	);
};
