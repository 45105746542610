import { cn } from '@/lib/utils';
import { format, setYear } from 'date-fns';
import { it } from 'date-fns/locale';
import { Calendar as CalendarIcon, XIcon } from 'lucide-react';
import { useState } from 'react';
import { Button } from '../button';
import { Calendar } from '../calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';

type DatePickerProps = {
	value?: Date | null;
	onChange?: (date?: Date | null) => void;
	placeholder?: string;
	className?: string;
	disabled?: boolean;
	isClearable?: boolean;
};

function DatePicker(props: DatePickerProps) {
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

	return (
		<Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
			<PopoverTrigger asChild>
				<Button
					disabled={props.disabled}
					variant={'outline'}
					className={cn(
						'w-full min-w-[240px] items-center justify-between text-left text-base font-normal',
						!props.value && 'text-muted-foreground hover:text-muted-foreground',
						props.value && 'capitalize',
						props.className
					)}>
					<span className="flex items-center justify-start text-sm">
						<CalendarIcon className="mr-3 h-4 w-4 text-muted-foreground" />
						{props.value ? (
							format(props.value, 'dd MMMM yyyy', { locale: it })
						) : (
							<span className="text-muted-foreground">{props.placeholder || 'Seleziona una data'}</span>
						)}
					</span>

					{props.value && props.isClearable && (
						<XIcon
							className="ml-2 h-4 w-4 text-muted-foreground hover:text-foreground"
							onClick={e => {
								e.preventDefault();
								props.onChange?.(null);
							}}
						/>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent align="start" className="w-auto p-0">
				<Calendar
					disabled={props.disabled}
					defaultMonth={props.value || new Date()}
					mode="single"
					selected={props.value || new Date()}
					onSelect={date => {
						props.onChange?.(date);
						setIsCalendarOpen(false);
					}}
					onYearChange={year => {
						if (!props.value) {
							return;
						}

						const newValue = setYear(props.value, year);
						props.onChange?.(newValue);
					}}
				/>
			</PopoverContent>
		</Popover>
	);
}

export { DatePicker };
