import { Heading } from '@/components/heading';
import { Outlet } from 'react-router-dom';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { ContactsCard } from './modules/contacts';
import { GeneralCard } from './modules/general';
import { MedicalCard } from './modules/medical';
import { OtherCard } from './modules/other';
import { ParentsCard } from './modules/parents';
import { PaymentsCard } from './modules/payments';
import { SportsCard } from './modules/sports';

export const Profile = () => {
	const { orgPerson } = useOrgPerson();

	return (
		<div>
			<Heading className="mb-5">Profilo</Heading>

			<div className="flex flex-col gap-5 pb-10">
				<GeneralCard
					name={orgPerson.name}
					surname={orgPerson.surname}
					fiscalCode={orgPerson.fiscalCode}
					avatarUrl={orgPerson.personMetadata.avatar}
					birthdate={orgPerson.personMetadata.dates?.birthdate}
				/>

				<MedicalCard expirationDate={orgPerson.personMetadata.dates?.medicalExaminationExpirationDate} />

				<ContactsCard
					email={orgPerson.contacts?.primaryEmail}
					phone={orgPerson.personMetadata.contacts?.primaryPhone}
					address={orgPerson.personMetadata.address}
				/>

				<ParentsCard parents={orgPerson.parents} />

				<PaymentsCard />

				<SportsCard orgPerson={orgPerson} />

				<OtherCard />
			</div>

			<Outlet />
		</div>
	);
};
