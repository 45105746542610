import { ErrorMessage, FieldConfig, useField } from 'formik';
import styled from 'styled-components';

import { Select, SelectProps } from '@gle/base-ui.inputs.select';
import { useCustomSelect } from '../../../hooks/use-custom-select';

export type SelectFieldProps = FieldConfig<any> &
	Omit<SelectProps, 'onBlur' | 'onFocus' | 'value'> & {
		readOnly?: boolean;
	};

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	min-height: 70px;
	flex-direction: column;
	column-gap: 3px;
	row-gap: 3px;
	gap: 3px;

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);
	}

	&.error {
		label {
			color: var(--danger-color);
		}

		.select__control {
			border-color: var(--danger-color);
		}
	}
`;

const CustomSelectField = ({
	onChange,
	placeholder = 'Seleziona...',
	...props
}: SelectFieldProps & { optionsKey: string }) => {
	const [field, meta, helpers] = useField(props as any);

	const { options } = useCustomSelect(props.optionsKey);

	const hasError = () => {
		return meta.touched && meta.error;
	};

	return (
		<Wrapper className={`${hasError() ? 'error' : ''}`}>
			<Select
				placeholder={placeholder}
				options={options}
				{...props}
				{...field}
				onChange={(option: any, meta: any) => {
					if (props.isMulti) {
						helpers.setValue(
							option?.map((t: any) => {
								if (t.hasOwnProperty('value')) {
									return t.value;
								}

								return t;
							})
						);
						onChange?.(option, meta);
					} else {
						helpers.setValue(option ? option.value : null);
						onChange?.(option, meta);
					}
				}}
				style={props.style}
			/>

			{hasError() && <ErrorMessage name={props.name} component="div" className={`form-field-error`} />}
		</Wrapper>
	);
};

export default CustomSelectField;
