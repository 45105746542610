import { ErrorMessage, FieldConfig, useField } from "formik";
import styled from "styled-components";

import { InputPhone, InputPhoneProps } from "@gle/base-ui.inputs.input-phone";

type Props = FieldConfig<any> &
  Omit<InputPhoneProps, "onChange" | "onBlur" | "onFocus" | "value">;

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  column-gap: 3px;
  row-gap: 3px;
  gap: 3px;

  .form-field-error {
    color: var(--danger-color);
    font-size: var(--xs-size);
  }

  &.error {
    label {
      color: var(--danger-color);
    }
    input {
      border-color: var(--danger-color);
    }
  }
`;

export const InputPhoneField = ({ ...props }: Props) => {
  const [field, meta, helpers] = useField(props);

  return (
    <Wrapper className={`${meta.touched && meta.error ? "error" : ""}`}>
      <InputPhone
        {...field}
        {...props}
        onChange={helpers.setValue}
        // TODO: fix this
        type="text"
      />
      <ErrorMessage
        name={props.name}
        component="div"
        className="form-field-error"
      />
    </Wrapper>
  );
};
