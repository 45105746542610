import { addContactToOrgPerson, changeContactToOrgPerson, removeContactFromOrgPerson } from '../api/people/contacts';
import { ContactType } from '../pages/private/types';

export const useOrgPersonEmailUpdate = () => {
	const addContact = async (orgPersonId: string, contact: string, contactType: ContactType, season: string) => {
		await addContactToOrgPerson({
			orgPersonId,
			contact,
			contactType,
			season,
		});
	};

	const changeContact = async (orgPersonId: string, contact: string, contactType: ContactType, season: string) => {
		await changeContactToOrgPerson({
			orgPersonId,
			contact,
			contactType,
			season,
		});
	};

	const removeContact = async (orgPersonId: string, contactType: ContactType, season: string) => {
		await removeContactFromOrgPerson({
			orgPersonId,
			contactType,
			season,
		});
	};

	const update = async (orgPersonId: string, season: string, oldEmail?: string, newEmail?: string) => {
		const cmd = getContactUpdateCommand(oldEmail, newEmail);

		try {
			if (cmd === 'add') {
				await addContact(orgPersonId, newEmail!, ContactType.primaryEmail, season);
			} else if (cmd === 'change') {
				await changeContact(orgPersonId, newEmail!, ContactType.primaryEmail, season);
			} else if (cmd === 'remove') {
				await removeContact(orgPersonId, ContactType.primaryEmail, season);
			}
		} catch (err: any) {
			throw err;
		}
	};

	return update;
};

export const getContactUpdateCommand = (
	oldEmail?: string,
	newEmail?: string
): 'add' | 'remove' | 'change' | undefined => {
	if (!oldEmail && newEmail) return 'add';

	if (!newEmail && oldEmail) return 'remove';

	if (oldEmail === newEmail) return undefined;

	return 'change';
};
