import currency from "currency.js";

export const Currency = ({ value }: { value: number }) => {
  return (
    <>
      {currency(value, {
        symbol: "€ ",
        decimal: ",",
      }).format()}
    </>
  );
};
