import { Card } from '@/components/card';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/drawer';
import { ChevronRightIcon, MapPinIcon, PaperclipIcon } from 'lucide-react';
import moment from 'moment';
import 'moment/locale/it';
import { useModal } from '../../../../hooks/use-modal';
import { CalendarEvent } from './../types';
import { EventPresenceActions } from './event-presence-actions';

export const EventCard = ({ event, orgPersonId }: { event: CalendarEvent; orgPersonId: string }) => {
	const detailModal = useModal();

	return (
		<>
			<Card className="overflow-hidden">
				<div className="flex flex-col gap-5 p-4 pb-5">
					<div className="flex gap-5">
						<div
							className="border-solid px-2 text-center text-lg"
							style={{
								borderLeftWidth: 5,
								borderLeftColor: event.color,
							}}>
							<p className="text-sm font-light uppercase text-slate-500">
								{moment(event.start).format('MMM')}
							</p>
							<h3 className="">{moment(event.start).format('DD')}</h3>
						</div>

						<div className="flex flex-col">
							<p className="font-medium">{event.title}</p>

							{event.allDay ? (
								<p className="text-sm text-slate-500">Tutto il giorno</p>
							) : (
								<p className="text-sm capitalize text-slate-500">
									{moment(event.start).format('dddd DD, HH:mm')} - {moment(event.end).format('HH:mm')}
								</p>
							)}

							{event.place && <p className="text-sm capitalize text-slate-500">{event.place}</p>}
						</div>
					</div>
				</div>

				<div
					className="flex items-center justify-between border-y border-solid border-slate-200 bg-slate-50 px-4 py-2 text-sm text-slate-500 transition-colors hover:bg-slate-100"
					onClick={() => detailModal.openModal()}>
					<p>Dettagli</p>
					<ChevronRightIcon size={18} />
				</div>

				<div className="bg-slate-100 px-4 py-2">
					<EventPresenceActions event={event} orgPersonId={orgPersonId} />
				</div>
			</Card>

			<Drawer
				open={detailModal.isModalVisible}
				onClose={detailModal.closeModal}
				onOpenChange={status => {
					if (!status) detailModal.closeModal();
				}}>
				<DrawerContent>
					<DrawerHeader>
						<DrawerTitle className="flex items-center gap-2">
							<span
								className="h-3 w-3 rounded"
								style={{
									backgroundColor: event.color,
								}}></span>
							{event.title}
						</DrawerTitle>
						<DrawerDescription className="flex flex-col items-start capitalize">
							<p>{moment(event.start).format('dddd DD MMMM')}</p>
							<p>
								{event.allDay ? (
									<span>Tutto il giorno</span>
								) : (
									<span>
										{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}{' '}
									</span>
								)}
							</p>
						</DrawerDescription>
					</DrawerHeader>

					<div className="p-4">
						<div className="flex flex-col gap-2">
							{event.place && (
								<p className="flex items-center gap-1 capitalize text-slate-500">
									<MapPinIcon size={18} />
									{event.place}
								</p>
							)}
							{event.attachment && (
								<a
									className="flex items-center gap-1 text-sky-700 hover:underline"
									target="_blank"
									href={event.attachment.url}>
									<span>
										<PaperclipIcon size={18} />
									</span>
									<span className="truncate">{event.attachment.fileName}</span>
								</a>
							)}
							{event.description && <p className="mt-1 text-slate-500">{event.description}</p>}
						</div>
					</div>
				</DrawerContent>
			</Drawer>
		</>
	);
};
