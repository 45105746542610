import { useProfiles } from '../../context/profiles/use-profiles';
import { InviteCard } from './invites-card.component';

export const InvitesList = () => {
	const { pendingInvites } = useProfiles();

	if (!pendingInvites || !pendingInvites.length) {
		return null;
	}
	return (
		<div>
			<p className="uppercase text-gray-400 text-xs mb-2">{'Inviti in sospeso'}</p>
			{pendingInvites.map(invite => (
				<InviteCard invite={invite} />
			))}
		</div>
	);
};
