import { Button } from '@/components/button';
import { DatePicker } from '@/components/date-picker';
import { Form, FormField, FormItem, FormLabel, FormMessage, FormSubmitButtons } from '@/components/form';
import { Input } from '@/components/input';
import { ReadOnlyValue } from '@/components/read-only-input';
import { useToast } from '@/components/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { setMetadata } from '../../../../../api/people/metadata';

const formSchema = z.object({
	identityDocumentNumber: z.string().optional(),
	identityDocumentExpirationDate: z.coerce.date().optional(),
	identityDocumentReleaseDate: z.coerce.date().optional(),
	attendedSchool: z.string().optional(),
	schoolSpecialism: z.string().optional(),
	iban: z.string().optional(),
});

type OtherFormProps = {
	orgPersonId: string;
	season: string;
	initialValues: z.infer<typeof formSchema>;
	onComplete: (updatedValues: { [key in keyof z.infer<typeof formSchema>]?: boolean | undefined }) => void;
	onCancel: () => void;
};

export const OtherForm = (props: OtherFormProps) => {
	const { toast } = useToast();

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: props.initialValues,
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setIsSaving(true);

		try {
			await setMetadata(
				{
					orgPersonId: props.orgPersonId,
					season: props.season,
				},
				{
					person: {
						academics: {
							attendedSchool: values.attendedSchool,
							schoolSpecialism: values.schoolSpecialism,
						},
						iban: values.iban,
						identityDocument: {
							number: values.identityDocumentNumber,
							releaseDate: values.identityDocumentReleaseDate?.toISOString(),
						},
						dates: {
							identityDocumentExpirationDate: values.identityDocumentExpirationDate?.toISOString(),
						},
					},
				}
			);

			toast({
				title: 'Salvataggio completato',
				description: 'I dati sono stati aggiornati con successo',
				variant: 'success',
			});

			props.onComplete(form.formState.dirtyFields);
		} catch (error) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsSaving(false);
		}
	};

	if (!isEdit) {
		return (
			<ReadOnlyOtherForm
				initialValues={props.initialValues}
				onEdit={() => {
					setIsEdit(true);
				}}
			/>
		);
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto flex flex-col gap-4">
				<FormField
					control={form.control}
					name="identityDocumentNumber"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Numero documento di identità</FormLabel>
							<Input placeholder="" type="text" {...field} />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="identityDocumentExpirationDate"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Data di scadenza documento di identità</FormLabel>
							<DatePicker value={field.value} onChange={field.onChange} isClearable />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="identityDocumentReleaseDate"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Data di rilascio documento di identità</FormLabel>
							<DatePicker value={field.value} onChange={field.onChange} isClearable />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="attendedSchool"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Scuola frequentata</FormLabel>
							<Input placeholder="" type="text" {...field} />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="schoolSpecialism"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Indirizzo scolastico</FormLabel>
							<Input placeholder="" type="text" {...field} />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="iban"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>IBAN</FormLabel>
							<Input placeholder="" type="text" {...field} />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormSubmitButtons
					isSaving={isSaving}
					onCancel={() => {
						setIsEdit(false);
					}}
				/>
			</form>
		</Form>
	);
};

const ReadOnlyOtherForm = (props: Pick<OtherFormProps, 'initialValues'> & { onEdit: () => void }) => {
	return (
		<div className="flex flex-col gap-4">
			<ReadOnlyValue
				type="text"
				label="Numero documento di identità"
				value={props.initialValues.identityDocumentNumber}
			/>
			<ReadOnlyValue
				label="Data di scadenza documento di identità"
				value={props.initialValues.identityDocumentExpirationDate?.toISOString()}
				type="date"
			/>
			<ReadOnlyValue
				label="Data di rilascio documento di identità"
				value={props.initialValues.identityDocumentReleaseDate?.toISOString()}
				type="date"
			/>
			<ReadOnlyValue type="text" label="Scuola frequentata" value={props.initialValues.attendedSchool} />
			<ReadOnlyValue type="text" label="Indirizzo scolastico" value={props.initialValues.schoolSpecialism} />
			<ReadOnlyValue type="text" label="IBAN" value={props.initialValues.iban} />

			<div className="flex justify-end">
				<Button variant="secondary" onClick={props.onEdit} type="button">
					Modifica dati
				</Button>
			</div>
		</div>
	);
};
