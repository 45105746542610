import { Select } from '@gle/base-ui.inputs.select';
import { useI18n } from '@gle/providers.i18n';
import axios from 'axios';
import { SelectFieldProps } from '../../core/inputs/Select/Field';

type Town = {
	name: string;
	province: string;
	code: string | null;
};

export const getItalianTowns = async (skip: number, limit: number, search: string) => {
	const URL = 'https://europe-west8-golee-infra.cloudfunctions.net/italian-towns-fetcher';

	try {
		const { data: towns } = await axios.get<Town[]>(URL, {
			params: {
				skip,
				limit,
				search,
			},
		});

		const hasMore = limit === towns.length;

		if (!search || search.toLowerCase().includes('est')) {
			if (skip === 0) {
				towns.unshift({
					name: 'Estero',
					code: null,
					province: 'EE',
				});
			}
		}

		return {
			options: towns.map(town => ({
				value: town.name,
				label: `${town.name} (${town.province})`,
				province: town.province,
			})),
			hasMore: hasMore,
			additional: { start: skip + limit, limit: limit },
		};
	} catch (error) {
		console.error('Error fetching Italian towns:', error);
		throw error;
	}
};

const onSearch = async (search: string) =>
	new Promise<any[]>(resolve => {
		if (!search || search.length < 3) {
		}

		getItalianTowns(0, 50, search).then(data => {
			resolve(data.options);
		});
	});

export const TownsSelectField = (props: Omit<SelectFieldProps, 'options'>) => {
	const { messages } = useI18n();

	return (
		<Select
			type="async"
			label={props.label}
			id="italian-town-list"
			loadingMessage={() => messages['loading']}
			cacheOptions
			isDisabled={props.isDisabled}
			defaultOptions
			loadOptions={onSearch}
			onChange={props.onChange}
			className={'select-creatable'}
			value={
				props.value
					? {
							label: props.value,
							value: props.value,
						}
					: null
			}
			isClearable={props.isClearable}
			placeholder={props.placeholder || `Digita il comune...`}
		/>
	);
};
