import { apiClient } from '../../../api/client';
import { Organization, OrgPerson, Parent } from '../types';

export const getOrganization = (organizationId: string) =>
	apiClient('club').get<Organization>(`club/${organizationId}`);

export const loadOrgPerson = (currentSeason: string) =>
	apiClient('people').get<OrgPerson>(`org-people/me`, {
		headers: {
			'x-gle-current-season': currentSeason,
			'x-gle-selected-season': currentSeason,
		},
	});

export const loadOrgPersonParents = (orgPersonId: string, currentSeason: string) =>
	apiClient('people').get<Parent[]>(`parent`, {
		params: {
			athlete: orgPersonId,
		},
		headers: {
			'x-gle-selected-season': currentSeason,
			'x-gle-current-season': currentSeason,
		},
	});

export const updateOrgPersonParent = (orgPersonId: string, parent: Partial<Parent>, currentSeason: string) =>
	apiClient('people').put<void>(
		`parent/${parent._id}`,
		{ ...parent, athlete: orgPersonId },
		{
			headers: {
				'x-gle-selected-season': currentSeason,
				'x-gle-current-season': currentSeason,
			},
		}
	);

export const createOrgPersonParent = (orgPersonId: string, parent: Partial<Parent>, currentSeason: string) =>
	apiClient('people').post<void>(
		`parent`,
		{ ...parent, athletes: [orgPersonId] },
		{
			headers: {
				'x-gle-selected-season': currentSeason,
				'x-gle-current-season': currentSeason,
			},
		}
	);

export const deleteOrgPersonParent = (orgPersonId: string, parentId: string, currentSeason: string) =>
	apiClient('people').delete<void>(`parent/${parentId}`, {
		data: {
			athlete: orgPersonId,
		},
		headers: {
			'x-gle-selected-season': currentSeason,
			'x-gle-current-season': currentSeason,
		},
	});
