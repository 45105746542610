import { Button } from '@/components/button';
import { DatePicker } from '@/components/date-picker';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormSubmitButtons } from '@/components/form';
import { Input } from '@/components/input';
import { ReadOnlyValue } from '@/components/read-only-input';
import { Textarea } from '@/components/textarea';
import { useToast } from '@/components/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { setMetadata } from '../../../../../api/people/metadata';
import { useSettings } from '../../../../../context/settings';

const formSchema = z.object({
	medicalExaminationExpirationDate: z.coerce.date().nullable().optional(),
	medicalExaminationScheduledDate: z.coerce.date().nullable().optional(),
	healthCardNumber: z.string().optional(),
	allergies: z.string().optional(),
});

type MedicalFormProps = {
	orgPersonId: string;
	season: string;
	initialValues: z.infer<typeof formSchema>;
	onComplete: (updatedValues: { [key in keyof z.infer<typeof formSchema>]?: boolean | undefined }) => void;
	onCancel: () => void;
};

const preparePayload = (
	values: z.infer<typeof formSchema>,
	allowMedicalExaminationEdit?: boolean
): Record<string, unknown> => {
	const { allergies, healthCardNumber, medicalExaminationExpirationDate, medicalExaminationScheduledDate } = values;

	const payload = {
		person: {
			medicalProfile: {
				allergies,
				healthCardNumber,
			},
			dates: {
				medicalExaminationScheduledDate: medicalExaminationScheduledDate?.toISOString() || null,
				...(allowMedicalExaminationEdit && {
					medicalExaminationExpirationDate: medicalExaminationExpirationDate?.toISOString() || null,
				}),
			},
		},
	};

	return payload;
};

export const MedicalForm = (props: MedicalFormProps) => {
	const { settings } = useSettings();
	const { toast } = useToast();

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: props.initialValues,
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setIsSaving(true);

		try {
			await setMetadata(
				{
					orgPersonId: props.orgPersonId,
					season: props.season,
				},
				preparePayload(values, settings.allowMedicalExaminationEdit)
			);

			toast({
				title: 'Salvataggio completato',
				description: 'I dati sono stati aggiornati con successo',
				variant: 'success',
			});

			props.onComplete(form.formState.dirtyFields);
		} catch (error) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsSaving(false);
		}
	};

	if (!isEdit) {
		return <ReadOnlyMedicalForm initialValues={props.initialValues} onEdit={() => setIsEdit(true)} />;
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto flex flex-col gap-4">
				{settings.allowMedicalExaminationEdit && (
					<FormField
						control={form.control}
						name="medicalExaminationExpirationDate"
						render={({ field }) => (
							<FormItem className="flex flex-col">
								<FormLabel>Data scadenza visita medica</FormLabel>
								<DatePicker value={field.value} onChange={field.onChange} isClearable />
								<FormMessage />
							</FormItem>
						)}
					/>
				)}

				<FormField
					control={form.control}
					name="medicalExaminationScheduledDate"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Data prevista visita medica</FormLabel>
							<DatePicker value={field.value} onChange={field.onChange} isClearable />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="healthCardNumber"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Tessera sanitaria</FormLabel>
							<FormControl>
								<Input placeholder="" type="text" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="allergies"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Allergie</FormLabel>
							<FormControl>
								<Textarea placeholder="" className="resize-none" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormSubmitButtons isSaving={isSaving} onCancel={() => setIsEdit(false)} />
			</form>
		</Form>
	);
};

const ReadOnlyMedicalForm = (props: Pick<MedicalFormProps, 'initialValues'> & { onEdit: () => void }) => {
	return (
		<div className="flex flex-col gap-4">
			<ReadOnlyValue
				type="date"
				label="Data scadenza visita medica"
				value={props.initialValues.medicalExaminationExpirationDate?.toISOString()}
			/>
			<ReadOnlyValue
				type="date"
				label="Data prevista visita medica"
				value={props.initialValues.medicalExaminationScheduledDate?.toISOString()}
			/>
			<ReadOnlyValue type="text" label="Tessera sanitaria" value={props.initialValues.healthCardNumber} />
			<ReadOnlyValue type="textarea" label="Allergie" value={props.initialValues.allergies} />

			<div className="flex justify-end">
				<Button variant="secondary" onClick={props.onEdit} type="button">
					Modifica dati
				</Button>
			</div>
		</div>
	);
};
