import { Button } from '@/components/button';
import { cn } from '@/lib/utils';
import { Text } from '@gle/base-ui.typography.text';
import { useConan } from '@golee/gle-conan-tracker';
import { useEffect, useRef, useState } from 'react';
import { setReaction } from '../../../../api/club/posts-feed';
import { useOrgPerson } from '../../../../context/org-person';

const Reactions = (props: { reaction: string; postId: string; type: string }) => {
	const { trackEvent } = useConan();
	const { orgPerson } = useOrgPerson();
	const [selectedReaction, setSelectedReaction] = useState<string>(props.reaction);

	const handleReaction = async (reaction: string) => {
		try {
			await setReaction(props.postId, reaction);

			void trackEvent('board.reaction-set', {
				org_person: orgPerson?.orgPersonId,
				board_type: props.type,
				reaction: reaction,
				post_id: props.postId,
			});
		} catch (e) {
			console.error(e);
		}
	};

	const selectReaction = (reaction: any) => {
		setSelectedReaction(reaction);
		handleReaction(reaction);
	};

	return <EmojiReaction selectedReaction={selectedReaction} setReaction={selectReaction} />;
};

const useOutsideClick = (callback: () => void, dep: any) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (dep) {
			const handleClickOutside = (event: MouseEvent) => {
				if (ref.current && !ref.current.contains(event.target as Node)) {
					callback();
				}
			};

			document.addEventListener('mousedown', handleClickOutside);

			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [dep]);

	return ref;
};

const EmojiReaction = (props: { selectedReaction: string; setReaction: (emoji: string) => void }) => {
	const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);

	const ref = useOutsideClick(() => {
		setShowEmojiPicker(false);
	}, showEmojiPicker);

	const openEmojiPicker = () => {
		setShowEmojiPicker(!showEmojiPicker);
	};

	const selectEmoji = (emoji: string) => {
		props.setReaction(emoji);
		setShowEmojiPicker(false);
	};

	return (
		<div className={'relative flex justify-start'} ref={ref}>
			<div onClick={openEmojiPicker}>
				{!props.selectedReaction ? (
					<Button
						size="sm"
						variant={'outline'}
						className="rounded-full border-dashed tracking-tighter text-zinc-600 grayscale">
						😄
					</Button>
				) : (
					<div className="rounded-full border-dashed bg-zinc-100 px-2.5 py-1 text-zinc-600">
						{props.selectedReaction}
					</div>
				)}
			</div>
			<EmojiPicker onSelect={selectEmoji} visible={showEmojiPicker} />
		</div>
	);
};

const emojiOptions = [
	{ description: 'funny', emoji: '😄' },
	{ description: 'like', emoji: '👍' },
	{ description: 'love', emoji: '❤️' },
	{ description: 'insightful', emoji: '💡' },
	{ description: 'applause', emoji: '👏' },
	{ description: 'fire', emoji: '🔥' },
	{
		description: 'check',
		emoji: '✅',
	},
];

const EmojiPicker = (props: { onSelect: (emoji: string) => void; visible: boolean }) => {
	const handleEmojiClick = (emoji: string) => {
		props.onSelect(emoji);
	};

	return (
		<div
			className={cn(
				'px absolute -top-12 left-1.5 inline-flex gap-x-1.5 rounded-full bg-gray-100 p-1 px-2 transition-all',
				{
					'visible opacity-100': props.visible,
					'invisible opacity-0': !props.visible,
				}
			)}>
			{emojiOptions.map((item, index) => (
				<div
					key={index}
					className={'cursor-pointer rounded p-0.5 hover:bg-gray-200'}
					onClick={() => handleEmojiClick(item.emoji)}>
					<Text size={'xxl'}>{item.emoji}</Text>
				</div>
			))}
		</div>
	);
};

export default Reactions;
