import { Outlet } from 'react-router-dom';
import { useOrgPerson } from '../../../../context/org-person/use-org-person';
import { DocumentList } from './document-list';

export const PublicDocumentList = () => {
	const { organization } = useOrgPerson();

	return (
		<div className="mt-5">
			<DocumentList typeEntity={'organization'} refEntity={organization._id} />
			<Outlet />
		</div>
	);
};
