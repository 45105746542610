import { FormikContextType } from 'formik';
import { Metadata } from '../../api/people/metadata';
import { OrgPerson } from '../../context/org-person';

export type OrgPersonFormProps = {
	/**
	 * Org person in question.
	 */
	orgPerson?: OrgPerson;

	/**
	 * Force readOnly state to all fields.
	 */
	readOnly?: boolean;
};

export type OrgPersonFormFieldProps = {
	formContext: FormikContextType<Metadata>;
	readOnly?: boolean;
};

export enum ContactType {
	primaryEmail = 'primaryEmail',
}
