import { Popover, PopoverContent, PopoverTrigger } from '@/components/popover';
import { ChevronDownIcon } from 'lucide-react';
import { useState } from 'react';
import { useSeason } from '../../context/season';
import { readableSeason } from './readable-season';

export const SeasonSelector = () => {
	const { seasons, selectedSeason, selectSeason } = useSeason();
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const onSeasonSelected = (season: string) => {
		selectSeason(season);
	};

	if (seasons.length === 1) return <></>;

	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger>
				<div className={'flex items-center gap-1 text-slate-500'}>
					<p>{readableSeason(selectedSeason)}</p>
					<ChevronDownIcon size={16} />
				</div>
			</PopoverTrigger>
			<PopoverContent className="max-w-[150px] p-0">
				{seasons.map((season, i) => (
					<div key={season} className="border-b border-solid border-slate-100 p-2">
						<p
							onClick={(e: any) => {
								onSeasonSelected(season);
								setIsOpen(false);
							}}>
							{readableSeason(season)}
						</p>
					</div>
				))}
			</PopoverContent>
		</Popover>
	);
};
