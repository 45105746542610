import { useContext } from 'react';
import { CacheContext, CacheContextType } from './cache-context';

export const useCache = <T>() => {
	const context = useContext(CacheContext) as CacheContextType<T>;

	if (!context) {
		throw new Error('useCache must be used within CacheProvider');
	}

	return context;
};
