import { EPlan, ERole } from '@golee/gle-types';
import { apiClient } from '../../api/client';

export enum EAccountRole { // should be taken from guardian sdk
	club_manager = 'club_manager',
	admin = 'admin',
	vice_club_manager = 'vice_club_manager',
	viewer_club_manager = 'viewer_club_manager',
	administrative_secretary = 'administrative_secretary',
	press_office = 'press_office',
	organization_person = 'organization_person',
	organization_coach = 'organization_coach',
}

export type Identity = {
	identityId: string;
	role: EAccountRole;
	profileId: string;
	userId: string;
	orgPersonId?: string;
	legacyLimitations?: {
		orgPeopleGroups: string[];
	};
	organizationId: string;
	grantedProducts: EPlan[];
	permissions: {
		[key: string]: 'allowed' | 'not-allowed' | 'need-plan:plus' | 'need-plan:premium';
	};
	isDelegate?: boolean;
};

export const allowedRoles = [ERole.parent, 'organization_person'];

export const loadIdentity = async (identityId: string): Promise<Identity> => {
	const { data: identity, status } = await apiClient('guardian').get<Identity>(`organization/identity/${identityId}`);

	if (status !== 200 || !identity) {
		window.location.href = window._env_.REACT_APP_AUTH_APP_URL!;
		throw new Error('Cannot retrieve user permissions from guardian');
	}

	if (!allowedRoles.includes(identity.role)) {
		throw new Error(`Role not allowed to this client`);
	}

	return identity;
};
