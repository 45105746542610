import { Card } from '@/components/card';
import { Carousel, CarouselContent, CarouselItem } from '@/components/carousel';
import { cn } from '@/lib/utils';
import Autoplay from 'embla-carousel-autoplay';
import { useNavigate } from 'react-router-dom';
import { Sponsor } from '../../api/club/sponsors';

export const SponsorsCarousel = ({ sponsors }: { sponsors: Sponsor[] }) => {
	const navigate = useNavigate();
	const filteredSponsors = sponsors.filter(s => s.isVisibleInApp && s.logoUrl);

	if (!filteredSponsors.length) return <></>;

	return (
		<div>
			<p className={'mb-2 text-center text-sm text-slate-500'}>I nostri sponsor</p>
			<Card className={cn('mb-5 overflow-hidden px-2 py-4')}>
				<Carousel
					plugins={[
						Autoplay({
							delay: 3000,
						}),
					]}
					opts={{
						align: 'center',
						loop: true,
					}}>
					<CarouselContent className="gap-5">
						{filteredSponsors.map(sponsor => (
							<CarouselItem
								key={sponsor.sponsorId}
								className="basis-1/3 md:basis-1/3"
								onClick={() => navigate('/sponsors')}>
								<div className="relative flex h-20 min-w-[80px] cursor-pointer flex-col content-center items-center justify-center gap-1 rounded-lg p-3 hover:border-slate-200 hover:bg-slate-50">
									{/* <img className={'aspect-auto h-12'} src={sponsor.logoUrl} alt={sponsor.name} /> */}
									<div
										className="h-full w-full bg-contain bg-center bg-no-repeat"
										style={{ backgroundImage: `url(${sponsor.logoUrl})` }}></div>
								</div>
							</CarouselItem>
						))}
					</CarouselContent>
				</Carousel>
			</Card>
		</div>
	);
};

const SponsorsList = ({
	sponsors,
	animate,
	ariaHidden,
}: {
	sponsors: Sponsor[];
	animate?: boolean;
	ariaHidden?: boolean;
}) => {
	const navigate = useNavigate();

	return (
		<ul
			onClick={() => navigate('/sponsors')}
			className={`${animate && 'animate-infinite-scroll'} scroll flex items-center justify-center [&_img]:max-w-none [&_li]:mx-6`}
			aria-hidden={ariaHidden}>
			{sponsors
				.filter(s => s.logoUrl)
				.map(sponsor => (
					<li
						key={sponsor.sponsorId}
						className="relative flex h-20 min-w-[80px] cursor-pointer flex-col content-center items-center justify-center gap-1 rounded-lg p-3 hover:border-gray-200 hover:bg-gray-50">
						<img className={'h-12'} src={sponsor.logoUrl} alt={sponsor.name} />
					</li>
				))}
		</ul>
	);
};
