import { PeriodFilter } from '../../../../components/period-filter';
import { OrgPerson, useOrgPerson } from '../../../../context/org-person';
import { useCalendarStats } from '../hooks';
import { StatisticsCounters } from './statistics-counters';

export const StatisticsTab = () => {
	const { orgPerson } = useOrgPerson();

	return <Statistics orgPerson={orgPerson} />;
};

export const Statistics = (props: { orgPerson: OrgPerson }) => {
	const { start, end, onPeriodChange, rangeType, stats, isLoading } = useCalendarStats(props.orgPerson.orgPersonId);

	return (
		<div>
			<div className="mb-4 py-1">
				<PeriodFilter
					from={start}
					to={end}
					onChange={onPeriodChange}
					allowedRanges={['week', 'month']}
					rangeType={rangeType}
				/>
			</div>

			<StatisticsCounters stats={stats} isLoading={isLoading} />
		</div>
	);
};
