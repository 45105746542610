import * as semver from 'semver/preload';

export const useReactNative = () => {
	const sendMessage = async (key: string, data: any) => {
		const message = JSON.stringify({ key, data });
		// @ts-ignore
		window.ReactNativeWebView?.postMessage(message);
	};

	// @ts-ignore
	const isFromApp = !!window.ReactNativeWebView;

	const appVersion = window.localStorage.getItem('appVersion');
	const isAppVersionGte = (version: string) => {
		return !!(appVersion && semver.gte(appVersion, version));
	};
	const hasPermissionCamera = window.localStorage.getItem('cameraPermission') === 'true';

	return { sendMessageToRN: sendMessage, isFromApp, appVersion, hasPermissionCamera, isAppVersionGte };
};
