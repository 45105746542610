import { useI18n } from '@gle/providers.i18n';
import { LoadingLayout } from '../../components';
import React from 'react';

export const I18NProvider: React.FC<{
	fallback: React.ReactNode;
	children: React.ReactNode;
}> = props => {
	const { isLoading: isI18nLoading } = useI18n();

	if (isI18nLoading) {
		return <LoadingLayout />;
	}

	return <>{props.children}</>;
};
