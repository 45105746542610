import { HTMLAttributes } from 'react';

const baseClass = `flex flex-col basis-0 grow shrink gap-2 items-center justify-between text-center border 
			border-solid 
			p-2
			border-gray-200
			rounded cursor-pointer`;

export const ReasonWrapper: React.FC<HTMLAttributes<HTMLDivElement> & { isActive: boolean }> = ({
	isActive,
	...props
}) => {
	return <div className={`${baseClass} ${isActive ? 'border-blue-400 bg-blue-50' : ''}`} {...props} />;
};
