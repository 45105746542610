import { Button } from '@/components/button';
import { Form, FormField, FormItem, FormLabel, FormMessage, FormSubmitButtons } from '@/components/form';
import { Input } from '@/components/input';
import { ReadOnlyValue } from '@/components/read-only-input';
import { useToast } from '@/components/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { setMetadata } from '../../../../../../api/people/metadata';
import { CountrySelect } from '../../../../../../components/selector/country-select-field';
import { TownsSelectField } from '../../../../../../components/selector/italian-towns-select-field';
import { ProvincesSelect } from '../../../../../../components/selector/provinces-select-field';
import { RegionsSelect } from '../../../../../../components/selector/regions-select-field';

const formSchema = z.object({
	nation: z.string().optional(),
	region: z.string().optional(),
	province: z.string().optional(),
	town: z.string().optional(),
	address: z.string().optional(),
	num: z.string().optional(),
	cap: z.string().optional(),
});

type AddressFormProps = {
	orgPersonId: string;
	season: string;
	initialValues: z.infer<typeof formSchema>;
	onComplete: (updatedValues: { [key in keyof z.infer<typeof formSchema>]?: boolean | undefined }) => void;
	onCancel: () => void;
	allowEdit: boolean;
	onEditStatusChanged: (isEdit: boolean) => void;
};

export const AddressForm = (props: AddressFormProps) => {
	const { toast } = useToast();

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: props.initialValues,
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setIsSaving(true);

		try {
			await setMetadata(
				{
					orgPersonId: props.orgPersonId,
					season: props.season,
				},
				{
					person: {
						address: values,
					},
				}
			);

			toast({
				title: 'Salvataggio completato',
				description: 'I dati sono stati aggiornati con successo',
				variant: 'success',
			});

			props.onComplete(form.formState.dirtyFields);
		} catch (error) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsSaving(false);
		}
	};

	if (!isEdit) {
		return (
			<ReadOnlyAddressForm
				initialValues={props.initialValues}
				allowEdit={props.allowEdit}
				onEdit={() => {
					setIsEdit(true);
					props.onEditStatusChanged(true);
				}}
			/>
		);
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto flex flex-col gap-4">
				<div className="flex gap-4">
					<FormField
						control={form.control}
						name="address"
						render={({ field }) => (
							<FormItem className="flex flex-[0.7] flex-col">
								<FormLabel>Indirizzo</FormLabel>
								<Input placeholder="" type="text" {...field} />
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="num"
						render={({ field }) => (
							<FormItem className="flex flex-[0.3] flex-col">
								<FormLabel>Num. Civico</FormLabel>
								<Input placeholder="" type="text" {...field} />
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>

				<FormField
					control={form.control}
					name="cap"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>CAP</FormLabel>
							<Input placeholder="" type="text" {...field} />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="town"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Comune</FormLabel>

							<TownsSelectField
								name={'town'}
								value={field.value}
								onChange={(opt: { value: string }) =>
									form.setValue('town', opt.value, { shouldDirty: true })
								}
							/>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="province"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Provincia</FormLabel>

							<ProvincesSelect
								value={field.value}
								onChange={(opt: { value: string }) =>
									form.setValue('province', opt.value, { shouldDirty: true })
								}
							/>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="region"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Regione</FormLabel>
							<RegionsSelect
								value={field.value}
								onChange={(opt: { value: string }) =>
									form.setValue('region', opt.value, { shouldDirty: true })
								}
							/>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="nation"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Nazione</FormLabel>
							<CountrySelect
								value={field.value}
								onChange={(opt: { value: string }) =>
									form.setValue('nation', opt.value, { shouldDirty: true })
								}
							/>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormSubmitButtons
					isSaving={isSaving}
					onCancel={() => {
						setIsEdit(false);
						props.onEditStatusChanged(false);
					}}
				/>
			</form>
		</Form>
	);
};

const ReadOnlyAddressForm = (
	props: Pick<AddressFormProps, 'initialValues'> & { onEdit: () => void; allowEdit: boolean }
) => {
	return (
		<div className="flex flex-col gap-4">
			<ReadOnlyValue
				type="textarea"
				label="Indirizzo"
				value={[
					props.initialValues.address,
					props.initialValues.num,
					props.initialValues.cap,
					props.initialValues.town,
					props.initialValues.province,
					props.initialValues.region,
					props.initialValues.nation,
				]
					.filter(part => !!part)
					.join(', ')}
			/>

			{props.allowEdit && (
				<div className="flex justify-end">
					<Button variant="secondary" onClick={props.onEdit} type="button">
						Modifica indirizzo
					</Button>
				</div>
			)}
		</div>
	);
};
