import { Button } from '@/components/button';
import { CheckIcon, XIcon } from 'lucide-react';
import { OrgPersonConsentStatus } from './types';

export const ConsentNotAnswered = (props: {
	onChange: (status: OrgPersonConsentStatus) => void;
	isLoading?: boolean;
}) => {
	return (
		<div className="flex items-center justify-end gap-4">
			<Button variant={'secondary'} onClick={() => props.onChange('given')} isLoading={props.isLoading}>
				{!props.isLoading && <CheckIcon className="mr-2 h-4 w-4" />} Accetto
			</Button>
			<Button variant={'secondary'} onClick={() => props.onChange('denied')} disabled={props.isLoading}>
				{!props.isLoading && <XIcon className="mr-2 h-4 w-4" />} Nego
			</Button>
		</div>
	);
};
