import { OrgPerson } from '../../context/org-person';
import { apiClient } from '../client';

export type MetadataParams = {
	orgPersonId: string;
	season: string;
};

export type Metadata = {
	id?: string;
	orgPersonId?: string;
	person?: OrgPerson['personMetadata'];
	athlete?: OrgPerson['metadata']['athlete'];
};

const getMetadata = async (params: MetadataParams) => {
	return await apiClient('people').get<Metadata>(`org-people/${params.orgPersonId}/metadata`, {
		headers: {
			'x-gle-current-season': params.season,
			'x-gle-selected-season': params.season,
		},
	});
};

const setMetadata = async (params: MetadataParams, payload: Metadata) => {
	return await apiClient('people').patch<Metadata>(`org-people/${params.orgPersonId}/metadata`, payload, {
		headers: {
			'x-gle-current-season': params.season,
			'x-gle-selected-season': params.season,
		},
	});
};

export { getMetadata, setMetadata };
