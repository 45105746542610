import { Button } from '@/components/button';
import { useConan } from '@golee/gle-conan-tracker';
import { DownloadIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { getReceipt, Receipt } from '../../../../../api/financial/receipts';

export const DownloadReceiptButton = ({ receiptId }: { receiptId: string }) => {
	const { trackEvent } = useConan();

	const [receipt, setReceipt] = useState<Receipt>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const fetchReceipt = async (id: string) => {
		setIsLoading(true);

		try {
			const resp = await getReceipt({ id });
			setReceipt(resp.data);
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (receiptId) {
			void fetchReceipt(receiptId);
		}
	}, [receiptId]);

	if (isLoading) {
		return (
			<Button variant={'secondary'} isLoading disabled>
				Caricamento ricevuta
			</Button>
		);
	}

	if (!receipt) {
		return (
			<Button variant={'secondary'} disabled>
				Ricevuta non disponibile
			</Button>
		);
	}

	return (
		<a href={receipt.documentUrl} target={'_blank'}>
			<Button variant={'secondary'} onClick={() => trackEvent('family.receipt-opened')}>
				<DownloadIcon size={18} className="mr-2" /> Scarica ricevuta
			</Button>
		</a>
	);
};
