import { Card, CardContent } from '@/components/card';
import { ProfileCardCallToAction } from '../../components';

export const OtherCard = () => {
	return (
		<Card>
			<CardContent className="flex flex-col p-0">
				<ProfileCardCallToAction
					to={'other'}
					label={'Visualizza altri dati del profilo'}
					className="justify-center border-t-0"
				/>
			</CardContent>
		</Card>
	);
};
