import { Button, ButtonProps } from '@/components/button';
import { ChevronLeftIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

export const NavigateBack = (props: { to: string; className?: ButtonProps['className'] }) => {
	return (
		<Button asChild variant={'secondary'} className={props.className}>
			<Link to={props.to}>
				<ChevronLeftIcon className="mr-2 h-4 w-4" /> Indietro
			</Link>
		</Button>
	);
};
