import { apiClient } from '../client';

export type Receipt = {
	id: string;
	receiptNumber: string;
	organizationId: string;
	createdAt: string;
	operations: string[];
	orgPersonId?: string;
	orgPartnerId?: string;
	holderDenomination: string;
	issuedAt: string;
	amount: number;
	type: string;
	season: string;
	deleted: boolean;
	metadata: any;
	documentUrl?: string;
	note?: string;
};

const getReceipt = (params: { id: string }) => apiClient('financial').get<Receipt>(`receipts/${params.id}`);

export { getReceipt };
