import { Avatar, AvatarFallback, AvatarImage } from '@/components/avatar';
import { Button } from '@/components/button';
import { DatePicker } from '@/components/date-picker';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormSubmitButtons } from '@/components/form';
import { Input } from '@/components/input';
import { ReadOnlyValue } from '@/components/read-only-input';
import { useToast } from '@/components/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Metadata, setMetadata } from '../../../../../api/people/metadata';
import { renameOrgPerson } from '../../../../../api/people/profiles';
import { ImageUploader } from '../../../../../components/image-uploader/image-uploader';
import { useSettings } from '../../../../../context/settings';

const formSchema = z.object({
	name: z.string(),
	surname: z.string(),
	fiscalCode: z.string(),
	birthdate: z.coerce.date().nullable().optional(),
	avatar: z.string().nullable().optional(),
});

type GeneralFormProps = {
	orgPersonId: string;
	season: string;
	initialValues: z.infer<typeof formSchema>;
	onComplete: (updatedValues: { [key in keyof z.infer<typeof formSchema>]?: boolean | undefined }) => void;
	onCancel: () => void;
};

export const GeneralForm = (props: GeneralFormProps) => {
	const { toast } = useToast();

	const { settings } = useSettings();

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: props.initialValues,
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setIsSaving(true);

		try {
			if (form.formState.dirtyFields.name || form.formState.dirtyFields.surname) {
				await renameOrgPerson({
					orgPersonId: props.orgPersonId,
					season: props.season,
					name: values.name,
					surname: values.surname,
				});
			}

			const metadataToUpdate: Partial<Metadata> = {};

			if (form.formState.dirtyFields.avatar) {
				metadataToUpdate.person = {
					avatar: values.avatar,
				};
			}

			if (form.formState.dirtyFields.birthdate) {
				metadataToUpdate.person = {
					dates: {
						birthdate: values.birthdate?.toISOString(),
					},
				};
			}

			if (Object.keys(metadataToUpdate).length) {
				await setMetadata({ orgPersonId: props.orgPersonId, season: props.season }, metadataToUpdate);
			}

			toast({
				title: 'Salvataggio completato',
				description: 'I dati sono stati aggiornati con successo',
				variant: 'success',
			});

			props.onComplete(form.formState.dirtyFields);
		} catch (error) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsSaving(false);
		}
	};

	if (!isEdit) {
		return <ReadOnlyGeneralForm initialValues={props.initialValues} onEdit={() => setIsEdit(true)} />;
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto flex flex-col gap-4">
				<ImageUploader
					url={form.getValues('avatar')}
					onChange={url => {
						form.setValue('avatar', url, { shouldDirty: true });
					}}
				/>

				{!settings.disablePersonalInformationEdit && (
					<>
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem className="flex flex-col">
									<FormLabel>Nome</FormLabel>
									<Input placeholder="" type="text" {...field} />
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="surname"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Cognome</FormLabel>
									<FormControl>
										<Input placeholder="" type="text" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="birthdate"
							render={({ field }) => (
								<FormItem className="flex flex-col">
									<FormLabel>Data di nascita</FormLabel>
									<DatePicker value={field.value} onChange={field.onChange} />
									<FormMessage />
								</FormItem>
							)}
						/>
					</>
				)}

				<FormSubmitButtons isSaving={isSaving} onCancel={() => setIsEdit(false)} />
			</form>
		</Form>
	);
};

const ReadOnlyGeneralForm = (props: Pick<GeneralFormProps, 'initialValues'> & { onEdit: () => void }) => {
	return (
		<div className="flex flex-col gap-4">
			<Avatar className="h-16 w-16">
				<AvatarImage alt="Profile picture" src={props.initialValues.avatar || undefined} />
				<AvatarFallback>
					{props.initialValues.name[0]}
					{props.initialValues.surname[0]}
				</AvatarFallback>
			</Avatar>

			<ReadOnlyValue type="text" label="Nome" value={props.initialValues.name} />
			<ReadOnlyValue type="text" label="Cognome" value={props.initialValues.surname} />
			<ReadOnlyValue type="text" label="Codice fiscale" value={props.initialValues.fiscalCode} />
			<ReadOnlyValue type="date" label="Data di nascita" value={props.initialValues.birthdate?.toISOString()} />

			<div className="flex justify-end">
				<Button variant="secondary" onClick={props.onEdit} type="button">
					Modifica dati
				</Button>
			</div>
		</div>
	);
};
