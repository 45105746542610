import { TObjTranche } from '@golee/gle-types';
import { apiClient } from '../client';

export const getTranches = async (params: { season: string; ref_org_person: string }) => {
	try {
		const { season, ref_org_person } = params;
		return apiClient('financial').get<TObjTranche[]>('tranche', {
			params: {
				ref_org_person,
				limit: 50,
			},
			headers: {
				'x-gle-current-season': season,
				'x-gle-selected-season': season,
			},
		});
	} catch (err) {
		throw err;
	}
};

export const getTranche = async (params: { id: string; season: string }) => {
	try {
		const { season } = params;

		return apiClient('financial').get(`tranche/${params.id}`, {
			headers: {
				'x-gle-current-season': season,
				'x-gle-selected-season': season,
			},
		});
	} catch (err) {
		throw err;
	}
};
