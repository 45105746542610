import { cn } from '@/lib/utils';
import { it } from 'date-fns/locale';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';
import { DayPicker, DropdownProps } from 'react-day-picker';
import { buttonVariants } from '../button';
import { ScrollArea } from '../scroll-area';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../select';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
	onYearChange?: (year: number) => void;
};

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
	return (
		<DayPicker
			locale={it}
			showOutsideDays={showOutsideDays}
			className={cn('p-3', className)}
			classNames={{
				months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
				month: 'space-y-4',
				caption: 'flex justify-center pt-1 relative items-center',
				caption_label: 'text-sm font-medium capitalize',
				caption_dropdowns: 'flex justify-center gap-1',
				nav: 'space-x-1 flex items-center',
				nav_button: cn(
					buttonVariants({ variant: 'outline' }),
					'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
				),
				nav_button_previous: 'absolute left-1',
				nav_button_next: 'absolute right-1',
				table: 'w-full border-collapse space-y-1',
				head_row: 'flex',
				head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem] capitalize',
				row: 'flex w-full mt-2',
				cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
				day: cn(buttonVariants({ variant: 'ghost' }), 'h-9 w-9 p-0 font-normal aria-selected:opacity-100'),
				day_selected:
					'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
				day_today: 'bg-accent text-accent-foreground',
				day_outside: 'text-muted-foreground opacity-50',
				day_disabled: 'text-muted-foreground opacity-50',
				day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
				day_hidden: 'invisible',
				vhidden: 'vhidden hidden',
				...classNames,
			}}
			components={{
				Dropdown: ({ value, onChange, children, ...dropdownProps }: DropdownProps) => {
					const isMonthDropdown = dropdownProps.name === 'months';

					const options = React.Children.toArray(children) as React.ReactElement<
						React.HTMLProps<HTMLOptionElement>
					>[];

					const selected = options.find(child => child.props.value === value);

					const handleChange = (value: string) => {
						const changeEvent = {
							target: { value },
						} as React.ChangeEvent<HTMLSelectElement>;

						if (!isMonthDropdown) {
							props.onYearChange?.(Number(value));
						}

						onChange?.(changeEvent);
					};

					return (
						<Select
							value={value?.toString()}
							onValueChange={value => {
								handleChange(value);
							}}>
							<SelectTrigger
								className={cn('pr-1.5 capitalize focus:ring-0', isMonthDropdown && 'min-w-[104px]')}>
								<SelectValue>{selected?.props?.children}</SelectValue>
							</SelectTrigger>
							<SelectContent position="popper">
								<ScrollArea className="h-80">
									{options.map((option, id: number) => (
										<SelectItem
											className="capitalize"
											key={`${option.props.value?.toString() ?? ''}-${id}`}
											value={option.props.value?.toString() ?? ''}>
											{option.props.children}
										</SelectItem>
									))}
								</ScrollArea>
							</SelectContent>
						</Select>
					);
				},
				IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
				IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
			}}
			{...props}
		/>
	);
}
Calendar.displayName = 'Calendar';

export { Calendar };
