import { ErrorMessage, FieldConfig, useField } from 'formik';
import styled from 'styled-components';

import { Input } from '@gle/base-ui.inputs.input';
import { Select, SelectProps } from '@gle/base-ui.inputs.select';
import { useUpdatedFieldProps } from '../use-updated-field-props';

export type SelectFieldProps = FieldConfig<any> &
	Omit<SelectProps, 'onBlur' | 'onFocus' | 'value'> & {
		readOnly?: boolean;
		optionsKey?: string;
	};

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	min-height: 70px;
	flex-direction: column;
	column-gap: 3px;
	row-gap: 3px;
	gap: 3px;

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);
	}

	&.error {
		label {
			color: var(--danger-color);
		}

		.select__control {
			border-color: var(--danger-color);
		}
	}
`;

const SelectField = ({ onChange, placeholder = 'Seleziona...', ..._props }: SelectFieldProps) => {
	const [field, meta, helpers] = useField(_props as any);
	const props = useUpdatedFieldProps<SelectFieldProps>(_props, 'select');

	const hasError = () => {
		return meta.touched && meta.error;
	};

	const getReadOnlyValue = () => {
		let value: string | undefined = undefined;

		if (props.type === 'async' || props.type === 'async-creatable' || props.type === 'creatable') {
			return undefined;
		}

		const options = props.options || [];

		if (!options.length) {
			return undefined;
		}

		if (props.isMulti) {
			if (Array.isArray(props.value)) {
				value = props.value
					.map((v: string) => options.find((opt: any) => opt.value === v))
					.filter(opt => !!opt)
					.map((opt: any) => opt?.label)
					.join(', ');
			}
		} else {
			value = (options as any).find((option: any) => option.value === props.value)?.label;
		}

		return value;
	};

	return (
		<Wrapper className={`${hasError() ? 'error' : ''}`}>
			{props.readOnly ? (
				<Input readOnly label={props.label} value={getReadOnlyValue()} />
			) : (
				<Select
					placeholder={placeholder}
					{...props}
					{...field}
					onChange={(option: any, meta: any) => {
						if (props.isMulti) {
							helpers.setValue(
								option?.map((t: any) => {
									if (t.hasOwnProperty('value')) {
										return t.value;
									}

									return t;
								})
							);
							onChange?.(option, meta);
						} else {
							helpers.setValue(option ? option.value : null);
							onChange?.(option, meta);
						}
					}}
					style={props.style}
				/>
			)}

			{hasError() && <ErrorMessage name={props.name} component="div" className={`form-field-error`} />}
		</Wrapper>
	);
};

export default SelectField;
