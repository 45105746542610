/**
 * Disclaimer:
 *
 * This is a porting of a Svelte project. It is not perfect and surely can be improved.
 * For now, it works fine for our use case.
 *
 * If you're trying to modify this code, please refer to: https://github.com/ShizukuIchi/pdf-editor
 */

import { MoveDiagonal2Icon, XIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { usePannable } from './utils/use-pannable';

type ImageProps = {
	payload: any;
	file: any;
	width: number;
	height: number;
	x: number;
	y: number;
	pageScale: number;
	disableEdit?: boolean;
	zoomScale: number;
	onUpdate: (payload: { width?: number; height?: number; x?: number; y?: number; file?: File }) => void;
	onDelete: () => void;
};

type Operation = 'move' | 'scale';
type Direction = 'right-top' | 'right-bottom' | 'left-top' | 'left-bottom' | 'left' | 'top' | 'right' | 'bottom';

const Image = ({ payload, file, width, height, x, y, pageScale = 1, onUpdate, onDelete, ...props }: ImageProps) => {
	const [startX, setStartX] = useState<number>(0);
	const startXRef = useRef<number>(startX);
	const [startY, setStartY] = useState<number>(0);
	const startYRef = useRef<number>(startY);

	const [dx, setDx] = useState(0);
	const dxRef = useRef<number>(dx);
	const [dy, setDy] = useState(0);
	const dyRef = useRef<number>(dy);

	const [dw, setDw] = useState(0);
	const dwRef = useRef<number>(dw);
	const [dh, setDh] = useState(0);
	const dhRef = useRef<number>(dh);

	const [operation, setOperation] = useState<Operation | undefined>(undefined);
	const operationRef = useRef<Operation | undefined>(operation);

	const [direction, setDirection] = useState<Direction | undefined>(undefined);
	const directionRef = useRef<Direction | undefined>(direction);

	const canvasRef = useRef<any>(null);

	useEffect(() => {
		operationRef.current = operation;
	}, [operation]);

	useEffect(() => {
		startXRef.current = startX;
	}, [startX]);

	useEffect(() => {
		startYRef.current = startY;
	}, [startY]);

	useEffect(() => {
		dxRef.current = dx;
	}, [dx]);

	useEffect(() => {
		dyRef.current = dy;
	}, [dy]);

	useEffect(() => {
		directionRef.current = direction;
	}, [direction]);

	useEffect(() => {
		dwRef.current = dw;
	}, [dw]);

	useEffect(() => {
		dhRef.current = dh;
	}, [dh]);

	const render = async () => {
		const canvas = canvasRef.current;

		if (!canvas.dataset.init) {
			canvas.width = width;
			canvas.height = height;
		}

		canvas.dataset.init = true;

		canvas.getContext('2d').drawImage(payload, 0, 0);

		let scale = 1;
		const limit = 500;

		if (width > limit) {
			scale = limit / width;
		}

		if (height > limit) {
			scale = Math.min(scale, limit / height);
		}

		onUpdate({
			width: width * scale,
			height: height * scale,
		});

		if (!['image/jpeg', 'image/png'].includes(file.type)) {
			canvas.toBlob((blob: any) => {
				onUpdate({
					file: blob,
				});
			});
		}
	};

	useEffect(() => {
		render();
	}, [payload, width, height, file, pageScale]);

	const handlePanMove = (event: {
		detail: { x: number; y: number; dx: number; dy: number; target: any };
		originalEvent: MouseEvent | TouchEvent;
	}) => {
		const _dx = (event.detail.x - startXRef.current) / (pageScale * props.zoomScale);
		const _dy = (event.detail.y - startYRef.current) / (pageScale * props.zoomScale);

		if (operationRef.current === 'move') {
			setDx(_dx);
			setDy(_dy);
			return;
		}

		if (operationRef.current === 'scale') {
			if (directionRef.current === 'left') {
				// setDx(_dx);
				// setDw(-_dx);
			} else if (directionRef.current === 'left-top') {
				setDy(_dy);
				setDx(_dx);
				setDw(-_dx);
				setDh(-_dy);
			} else if (directionRef.current === 'top') {
				// setDh(-_dy);
				// setDy(_dy);
			} else if (directionRef.current === 'right-top') {
				// setDw(_dx);
				// setDh(-_dy);
				// setDy(_dy);
			} else if (directionRef.current === 'right') {
				// setDw(_dx);
			} else if (directionRef.current === 'right-bottom') {
				setDw(_dx);
				setDh(_dy);
			} else if (directionRef.current === 'bottom') {
				// setDh(_dy);
			} else if (directionRef.current === 'left-bottom') {
				// setDw(-_dx);
				// setDh(_dy);
				// setDx(_dx);
			}
		}
	};

	const handlePanEnd = (event: {
		detail: { x: number; y: number; dx: number; dy: number };
		originalEvent: MouseEvent | TouchEvent;
	}) => {
		if (operationRef.current === 'move') {
			onUpdate({
				x: x + dxRef.current,
				y: y + dyRef.current,
			});
			setDx(0);
			setDy(0);
		} else if (operationRef.current === 'scale') {
			onUpdate({
				x: x + dxRef.current,
				y: y + dyRef.current,
				width: width + dwRef.current,
				height: height + dhRef.current,
			});

			setDx(0);
			setDy(0);
			setDw(0);
			setDh(0);

			setDirection(undefined);
		}

		setOperation(undefined);
	};

	const handlePanStart = (event: {
		detail: { x: number; y: number; target: any };
		originalEvent: MouseEvent | TouchEvent;
	}) => {
		setStartX(event.detail.x);
		setStartY(event.detail.y);

		if (event.detail.target.dataset.direction) {
			setOperation('scale');
			setDirection(event.detail.target.dataset.direction);
		} else {
			if (event.detail.target === event.originalEvent.target) {
				setOperation('move');
			}
		}
	};

	const nodeRef = usePannable(handlePanStart, handlePanMove, handlePanEnd);

	return (
		<div
			className={`absolute left-0 top-0 select-none ${props.disableEdit ? 'pointer-events-none' : ''}`}
			style={{
				width: `${width + dw}px`,
				height: `${height + dh}px`,
				transform: `translate(${x + dx}px, ${y + dy}px)`,
			}}>
			<div
				ref={nodeRef}
				className={`absolute h-full w-full cursor-grab${
					operation === 'move' ? 'cursor-grabbing' : ''
				} ${operation ? 'bg-blue-500 bg-opacity-20' : ''} ${!props.disableEdit ? 'border border-dashed border-zinc-200' : ''}`}>
				{/* Borders */}
				<div
					data-direction="left"
					className={`absolute left-0 top-0 h-full w-1 cursor-ew-resize border-l border-dashed border-blue-300 ${props.disableEdit ? 'hidden' : ''}`}
				/>
				<div
					data-direction="top"
					className={`absolute left-0 top-0 h-1 w-full cursor-ns-resize border-t border-dashed border-blue-300 ${props.disableEdit ? 'hidden' : ''}`}
				/>
				<div
					data-direction="bottom"
					className={`absolute bottom-0 left-0 h-1 w-full cursor-ns-resize border-b border-dashed border-blue-300 ${props.disableEdit ? 'hidden' : ''}`}
				/>
				<div
					data-direction="right"
					className={`absolute right-0 top-0 h-full w-1 cursor-ew-resize border-r border-dashed border-blue-300 ${props.disableEdit ? 'hidden' : ''}`}
				/>

				{/* Angles */}
				<div
					data-direction="left-top"
					className={`absolute left-0 top-0 h-9 w-9 -translate-x-1/2 -translate-y-1/2 transform cursor-nwse-resize rounded-full bg-blue-300 ${props.disableEdit ? 'hidden' : ''} flex items-center justify-center text-blue-800 active:bg-blue-400`}>
					<MoveDiagonal2Icon className="pointer-events-none" />
				</div>
				{/* <div
					data-direction="right-top"
					className={`absolute w-5 h-5 bg-blue-300 rounded-full right-0 top-0 cursor-nesw-resize transform translate-x-1/2 -translate-y-1/2 md:scale-25 ${props.disableEdit ? 'hidden' : ''}`}
				/>
				<div
					data-direction="left-bottom"
					className={`absolute w-5 h-5 bg-blue-300 rounded-full left-0 bottom-0 cursor-nesw-resize transform -translate-x-1/2 translate-y-1/2 md:scale-25 ${props.disableEdit ? 'hidden' : ''}`}
				/> */}
				<div
					data-direction="right-bottom"
					className={`absolute bottom-0 right-0 h-9 w-9 translate-x-1/2 translate-y-1/2 transform cursor-nwse-resize rounded-full bg-blue-300 ${props.disableEdit ? 'hidden' : ''} flex items-center justify-center text-blue-800 active:bg-blue-400`}>
					<MoveDiagonal2Icon className="pointer-events-none" />
				</div>
			</div>

			<div
				onClick={onDelete}
				className={`absolute left-0 right-0 top-0 m-auto h-5 w-5 -translate-y-1/2 transform cursor-pointer rounded-full ${props.disableEdit ? 'hidden' : ''} flex items-center justify-center bg-red-700 text-white`}>
				<XIcon className="pointer-events-none" />
			</div>
			<canvas className="h-full w-full" ref={canvasRef} />
		</div>
	);
};

export default Image;
