import { cn } from '@/lib/utils';

type GoleeLogoProps = {
	size?: 'sm' | 'md' | 'lg';
	className?: React.SVGAttributes<SVGSVGElement>['className'];
};

const GoleeLogo = ({ size = 'md', className }: GoleeLogoProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 150 65"
			fill="none"
			className={cn(
				'fill-primary',
				{
					'w-10': size === 'sm',
					'w-16': size === 'md',
					'w-20': size === 'lg',
				},
				className
			)}>
			<path d="M36.496 30.454c0-9.17 6.521-15.545 15.257-15.545S67.01 21.303 67.01 30.454 60.49 46 51.753 46s-15.257-6.4-15.257-15.545zm23.332 0c0-5.385-3.468-8.968-8.075-8.968-4.606 0-8.075 3.583-8.075 8.968 0 5.386 3.469 8.968 8.075 8.968 4.607 0 8.075-3.588 8.075-8.968zm13.166 15.447V1.063L80.176.055v44.838L72.994 45.9zM23.332 15.875v3.399a11.364 11.364 0 00-8.974-4.365C6.16 14.909 0 21.303 0 30.454S6.16 46 14.358 46a11.393 11.393 0 008.974-4.377v3.766c0 5.257-2.87 8.968-7.775 8.968a7.473 7.473 0 01-7.604-6.168l-7.103.996c1.548 7.06 7.231 11.762 14.682 11.762 8.259 0 14.957-6.4 14.957-15.546V14.866l-7.157 1.009zm-8.075 23.547c-4.607 0-8.075-3.588-8.075-8.968s3.468-8.968 8.075-8.968c4.606 0 8.075 3.583 8.075 8.968 0 5.386-3.469 8.968-8.075 8.968zm126.185-2.983c-.961 2.084-2.814 3.282-5.983 3.282-4.429 0-7.298-2.512-8.075-6.877h22.494c.061-.776.122-1.552.122-2.39 0-9.17-6.283-15.545-14.841-15.545s-14.835 6.394-14.835 15.545S126.631 46 135.159 46c6.65 0 11.654-2.977 13.715-8.521l-7.432-1.04zm-6.283-15.283c4.19 0 6.943 2.39 7.72 6.578h-15.44c.783-4.157 3.536-6.547 7.72-6.547v-.03zm-27.584 15.283c-.961 2.084-2.814 3.282-5.983 3.282-4.429 0-7.298-2.512-8.075-6.877h22.494c.061-.776.122-1.552.122-2.39 0-9.17-6.282-15.545-14.841-15.545-8.558 0-14.835 6.394-14.835 15.545S92.74 46 101.292 46c6.656 0 11.623-2.977 13.716-8.521l-7.433-1.04zm-6.283-15.283c4.191 0 6.944 2.39 7.721 6.578H93.597c.758-4.157 3.511-6.547 7.695-6.547v-.03z"></path>
		</svg>
	);
};

export { GoleeLogo };
