import { Card, CardContent } from '@/components/card';
import { Progress } from '@/components/progress';
import { Spinner } from '@/components/spinner';
import { cn } from '@/lib/utils';
import { ParticipantStatistics } from '../types';

const defaultStats: ParticipantStatistics = {
	eventsCount: 0,
	terminatedEventsCount: 0,
	presences: {
		trueCount: 0,
		falseCount: 0,
		unansweredCount: 0,
	},
};

export const StatisticsCounters = ({
	stats = defaultStats,
	isLoading,
}: {
	stats?: ParticipantStatistics;
	isLoading?: boolean;
}) => {
	const totalResponses = stats.presences.trueCount + stats.presences.falseCount + stats.presences.unansweredCount;
	const presentPercentage = totalResponses > 0 ? (stats.presences.trueCount / totalResponses) * 100 : 0;

	if (isLoading) {
		return (
			<Card className="mx-auto w-full">
				<CardContent className="flex flex-col items-center justify-center p-6">
					<Spinner />
				</CardContent>
			</Card>
		);
	}

	return (
		<Card className="mx-auto w-full">
			<CardContent className="space-y-6 pt-6">
				<div className="grid grid-cols-2 gap-4 text-center">
					<div>
						<p className="text-2xl font-bold">{stats.terminatedEventsCount}</p>
						<p className="text-sm text-muted-foreground">Eventi terminati</p>
					</div>
					<div>
						<p className="text-2xl font-bold">{stats.eventsCount}</p>
						<p className="text-sm text-muted-foreground">Eventi totali</p>
					</div>
				</div>

				<div className="space-y-2 border-t border-solid border-muted pt-4">
					{[
						{ label: 'Presente', value: stats.presences.trueCount },
						{ label: 'Assente', value: stats.presences.falseCount },
						{ label: 'Senza risposta', value: stats.presences.unansweredCount },
					].map(({ label, value }) => (
						<div key={label} className="flex items-center justify-between">
							<span className="text-sm">{label}</span>
							<span className="text-sm font-medium">{value}</span>
						</div>
					))}
				</div>

				<div className="space-y-2 border-t border-solid border-muted pt-4">
					<div className="flex items-center justify-between">
						<span className="text-sm font-medium">Percentuale di presenza</span>
						<span className="text-sm font-medium">{presentPercentage.toFixed(0)}%</span>
					</div>
					<Progress
						value={presentPercentage}
						className="w-full"
						indicatorClassName={cn({
							'bg-emerald-500': presentPercentage > 75,
							'bg-amber-500': presentPercentage <= 75 && presentPercentage > 50,
							'bg-red-500': presentPercentage <= 50,
						})}
					/>
				</div>
			</CardContent>
		</Card>
	);
};
