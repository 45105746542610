import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import { Label } from '../label';

type ReadOnlyValueProps = {
	label: string;
	value?: string;
	type?: 'text' | 'date' | 'textarea';
};

export const ReadOnlyValue = (props: ReadOnlyValueProps) => {
	if (props.type === 'date') {
		return (
			<div>
				<Label className="text-muted-foreground">{props.label}</Label>
				<div className="mt-1.5 flex items-center justify-start rounded-md border border-solid border-muted px-3 py-2">
					<CalendarIcon className="mr-3 h-4 w-4 text-muted-foreground" />
					{props.value ? (
						format(props.value, 'dd MMMM yyyy', { locale: it })
					) : (
						<span className="text-muted-foreground">{'Non impostata'}</span>
					)}
				</div>
			</div>
		);
	}

	if (props.type === 'textarea') {
		return (
			<div>
				<Label className="text-muted-foreground">{props.label}</Label>
				<div className="mt-1.5 min-h-20 rounded-md border border-solid border-muted px-3 py-2">
					{props.value || '-'}
				</div>
			</div>
		);
	}

	return (
		<div>
			<Label className="text-muted-foreground">{props.label}</Label>
			<div className="mt-1.5 rounded-md border border-solid border-muted px-3 py-2">{props.value || '-'}</div>
		</div>
	);
};
