import { useEffect, useRef } from 'react';

export function usePannable(
	onPanStart: (event: {
		detail: { x: number; y: number; target: any };
		originalEvent: MouseEvent | TouchEvent;
	}) => void,
	onPanMove: (event: {
		detail: { x: number; y: number; dx: number; dy: number; target: any };
		originalEvent: MouseEvent | TouchEvent;
	}) => void,
	onPanEnd: (event: {
		detail: { x: number; y: number; dx: number; dy: number };
		originalEvent: MouseEvent | TouchEvent;
	}) => void
) {
	const nodeRef = useRef<any>(null);

	useEffect(() => {
		const node = nodeRef.current;

		let x: number, y: number;

		const handleMousedown = (event: MouseEvent) => {
			event.stopPropagation();

			x = event.clientX;
			y = event.clientY;

			const target = event.target;

			onPanStart({ detail: { x, y, target }, originalEvent: event });

			window.addEventListener('mousemove', handleMousemove);
			window.addEventListener('mouseup', handleMouseup);
		};

		const handleMousemove = (event: MouseEvent) => {
			event.stopPropagation();

			const dx = event.clientX - x;
			const dy = event.clientY - y;
			x = event.clientX;
			y = event.clientY;

			onPanMove({ detail: { x, y, dx, dy, target: event.target }, originalEvent: event });
		};

		const handleMouseup = (event: MouseEvent) => {
			event.stopPropagation();

			const dx = event.clientX - x;
			const dy = event.clientY - y;
			x = event.clientX;
			y = event.clientY;

			onPanEnd({ detail: { x, y, dx, dy }, originalEvent: event });

			window.removeEventListener('mousemove', handleMousemove);
			window.removeEventListener('mouseup', handleMouseup);
		};

		const handleTouchStart = (event: TouchEvent) => {
			event.stopPropagation();

			if (event.touches.length > 1) return;
			const touch = event.touches[0];
			x = touch.clientX;
			y = touch.clientY;
			const target = touch.target;

			onPanStart({ detail: { x, y, target }, originalEvent: event });

			window.addEventListener('touchmove', handleTouchmove, { passive: false });
			window.addEventListener('touchend', handleTouchend);
		};

		const handleTouchmove = (event: TouchEvent) => {
			event.stopPropagation();

			event.preventDefault();
			if (event.touches.length > 1) return;
			const touch = event.touches[0];
			const dx = touch.clientX - x;
			const dy = touch.clientY - y;
			x = touch.clientX;
			y = touch.clientY;

			onPanMove({ detail: { x, y, dx, dy, target: event.target }, originalEvent: event });
		};

		const handleTouchend = (event: TouchEvent) => {
			event.stopPropagation();

			const touch = event.changedTouches[0];

			const dx = touch.clientX - x;
			const dy = touch.clientY - y;
			x = touch.clientX;
			y = touch.clientY;

			onPanEnd({ detail: { x, y, dx, dy }, originalEvent: event });

			window.removeEventListener('touchmove', handleTouchmove);
			window.removeEventListener('touchend', handleTouchend);
		};

		node.addEventListener('mousedown', handleMousedown);
		node.addEventListener('touchstart', handleTouchStart);

		return () => {
			node.removeEventListener('mousedown', handleMousedown);
			node.removeEventListener('touchstart', handleTouchStart);
		};
	}, [onPanStart, onPanMove, onPanEnd]);

	return nodeRef;
}
