import { useEffect, useState } from 'react';
import { apiClient } from '../../../api/client';
import { Consent } from './types';

export const useConsents = () => {
	const [data, setData] = useState<Consent[]>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		void apiClient('people')
			.get<Consent[]>(`org-consents`)
			.then(resp => {
				setData(resp.data);
				setIsLoading(false);
			});
	}, []);

	return { consents: data, isLoading };
};
