import { cn } from '@/lib/utils';
import { ChevronRightIcon } from 'lucide-react';
import { Link, LinkProps } from 'react-router-dom';

export const ProfileCardCallToAction = (
	props: Pick<LinkProps, 'to' | 'className'> & { label: string; icon?: React.ReactNode }
) => {
	return (
		<Link
			to={props.to}
			className={cn(
				'flex justify-end border-t border-solid border-slate-100 px-2 py-3 hover:bg-slate-50',
				props.className
			)}>
			<span className="inline-flex items-center gap-1 text-sm font-medium text-muted-foreground">
				{props.label} {props.icon || <ChevronRightIcon className="h-4 w-4" />}
			</span>
		</Link>
	);
};
