import { Button } from '@/components/button';
import { ChevronRightIcon } from 'lucide-react';
import { useReactNative } from '../../hooks/use-react-native';

export const GoToManager = () => {
	const { sendMessageToRN, isFromApp } = useReactNative();

	return (
		<Button
			variant={'secondary'}
			onClick={() => {
				if (isFromApp) {
					void sendMessageToRN('change-app', { appName: 'Manager' });
				} else {
					window.location.href = window._env_.REACT_APP_MANAGER_URL;
				}
			}}>
			{'Accedi al gestionale'} <ChevronRightIcon size={18} />
		</Button>
	);
};
