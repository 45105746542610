import { Button } from '@/components/button';
import { cn } from '@/lib/utils';
import { useConan } from '@golee/gle-conan-tracker';
import { RotateCcwIcon } from 'lucide-react';
import moment from 'moment';
import 'moment/locale/it';
import { useState } from 'react';
import { setParticipantPresence } from '../api';
import NotesModal from '../notes/notes-modal';
import { CalendarEvent } from '../types';

const isEventTerminated = (event: CalendarEvent) => moment(event.start) < moment();

const isDeadlinePresenceReached = (event: CalendarEvent) =>
	event.deadlinePresence && moment(event.deadlinePresence) < moment();

const presenceCannotBeSet = (event: CalendarEvent) => event.disableOrgPeoplePresenceSetting;

const useEventPresence = (event: CalendarEvent, orgPersonId: string) => {
	const { trackEvent } = useConan();

	const [presence, setPresence] = useState<boolean | null>(
		event.participant ? event.participant.orgPersonPresence : null
	);
	const [note, setNote] = useState<string | undefined>(event.participant?.orgPersonPresenceNote);

	const onPresenceSet = () => {
		if (presence === true) return;

		const _note = !presence ? '' : note;
		setNote(_note);
		setPresence(true);

		void setParticipantPresence(event._id, orgPersonId, true, _note);

		void trackEvent('calendar.event-participants-presence-set', {
			calendar_event: event._id,
			org_person: orgPersonId,
			presence: true,
			presence_note: _note,
			set_on: 'private-calendar',
		});
	};

	const onPresenceUnset = () => {
		if (presence === false) return;

		const _note = presence ? '' : note;
		setNote(_note);
		setPresence(false);

		void setParticipantPresence(event._id, orgPersonId, false, _note);
		void trackEvent('calendar.event-participants-presence-set', {
			calendar_event: event._id,
			org_person: orgPersonId,
			presence: false,
			presence_note: _note,
			set_on: 'private-calendar',
		});
	};

	const onPresenceCleared = () => {
		if (presence === null) return;

		setNote(undefined);
		setPresence(null);

		void setParticipantPresence(event._id, orgPersonId, null, null);
		void trackEvent('calendar.event-participants-presence-cleared', {
			calendar_event: event._id,
			org_person: orgPersonId,
			set_on: 'private-calendar',
		});
	};

	const onNoteSet = (_note?: string) => {
		if (presence === null) return;

		setNote(_note);
		void setParticipantPresence(event._id, orgPersonId, presence, _note);

		void trackEvent('calendar.event-participants-presence-set', {
			calendar_event: event._id,
			org_person: orgPersonId,
			presence,
			presence_note: _note,
			set_on: 'private-calendar',
		});
	};

	return { presence, note, onNoteSet, onPresenceSet, onPresenceUnset, onPresenceCleared };
};

export const EventPresenceActions = ({ event, orgPersonId }: { event: CalendarEvent; orgPersonId: string }) => {
	const { presence, note, onNoteSet, onPresenceSet, onPresenceUnset, onPresenceCleared } = useEventPresence(
		event,
		orgPersonId
	);

	if (isEventTerminated(event)) {
		return <TerminatedEventPresence presence={presence} />;
	}
	if (isDeadlinePresenceReached(event)) {
		return <DeadlinePresenceReached presence={presence} />;
	}

	if (presenceCannotBeSet(event)) {
		return <CannotSetPresence presence={presence} />;
	}

	return (
		<>
			<div className={cn('flex items-center justify-between', presence !== null && 'mb-3')}>
				<p>Ci sarai?</p>

				<div className="flex">
					<Button
						type="button"
						variant={presence ? 'success' : 'outline'}
						onClick={() => onPresenceSet()}
						className="w-12 rounded-br-none rounded-tr-none border-r-0">
						Si
					</Button>
					<Button
						type="button"
						variant={presence === false ? 'destructive' : 'outline'}
						onClick={() => onPresenceUnset()}
						className={cn(
							'w-12',
							presence !== null && 'rounded-none border-r-0',
							presence === null && 'rounded-bl-none rounded-tl-none'
						)}>
						No
					</Button>
					{presence !== null && (
						<Button
							type="button"
							size="icon"
							variant={'outline'}
							onClick={() => onPresenceCleared()}
							className="w-12 rounded-bl-none rounded-tl-none">
							<RotateCcwIcon size={18} className="text-slate-500" />
						</Button>
					)}
				</div>
			</div>

			{presence !== null && <NotesModal presence={presence} note={note} onSave={onNoteSet} />}
		</>
	);
};

const CannotSetPresence = ({ presence }: { presence: null | boolean; note?: string }) => {
	return (
		<div className="flex items-center justify-end">
			<Button
				variant={presence ? 'success' : 'outline'}
				className="cursor-auto rounded-br-none rounded-tr-none border-r-0">
				Presente
			</Button>
			<Button
				variant={presence === false ? 'destructive' : 'outline'}
				className="cursor-auto rounded-bl-none rounded-tl-none">
				Assente
			</Button>
		</div>
	);
};

const TerminatedEventPresence = ({ presence }: { presence: null | boolean; note?: string }) => {
	return (
		<div className="flex items-center justify-end">
			{presence === null && (
				<p className="text-sm text-zinc-600">{`Partecipazione all'evento non specificata`}</p>
			)}
			{presence === true && (
				<Button variant={'success'} size="sm" className="h-auto py-1">
					Hai partecipato a questo evento
				</Button>
			)}
			{presence === false && (
				<Button variant={'destructive'} size="sm" className="h-auto py-1">
					Non hai partecipato a questo evento
				</Button>
			)}
		</div>
	);
};

const DeadlinePresenceReached = ({ presence }: { presence: null | boolean; note?: string }) => {
	return (
		<div className="flex items-center justify-end">
			{presence === null && <p className="text-sm text-zinc-600">{`Non puoi più modificare la presenza`}</p>}
			{presence === true && (
				<Button variant={'success'} size="sm" className="h-auto py-1">
					Parteciperai a questo evento
				</Button>
			)}
			{presence === false && (
				<Button variant={'destructive'} size="sm" className="h-auto py-1">
					Non parteciperai a questo evento
				</Button>
			)}
		</div>
	);
};
