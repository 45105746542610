import { OrgPerson } from "../../../../../context/org-person";

export const isFromLazio = (orgPerson: OrgPerson) => {
    return orgPerson.personMetadata.address?.region === 'LAZ' ||
        orgPerson.personMetadata.birthplace?.toLocaleLowerCase() === 'roma'
};

export const addSpecificTemplates = (orgPerson: OrgPerson) => {
    const templates = []
    if (isFromLazio(orgPerson)) {
        templates.push(
            {
                _id: '9',
                unique_code: '',
                label: 'Attestazione iscrizione stagione sportiva 2024/2025 (Bando Lazio)',
            },
            {
                _id: '10',
                unique_code: '',
                label: 'Domanda per contributi sport giovani 2024 (Bando Lazio)',
            });
    }

    return templates
};