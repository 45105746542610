import { useContext } from 'react';
import { OrgPersonContext } from './org-person-context';

export const useOrgPerson = () => {
	const context = useContext(OrgPersonContext);

	if (!context) {
		throw new Error('useOrgPerson must be used within OrgPersonProvider');
	}

	return context;
};
