import React, { createContext, useContext, useEffect, useState } from 'react';
import { apiClient } from '../../api/client';
import { useProfiles } from '../profiles/use-profiles';

export type Settings = {
	paymentsDescription?: string | null;
	disablePersonalInformationEdit?: boolean;
	allowMedicalExaminationEdit?: boolean;
	allowParentsEdit?: boolean;
};

const getOrganizationSettings = () => {
	return apiClient('club').get<Settings>(`settings/app`);
};

type SettingsContextType = {
	settings: Settings;
};

export const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export const SettingsProvider = (props: { children: React.ReactNode }) => {
	const { selectedProfile } = useProfiles();
	const [settings, setSettings] = useState<Settings>({});

	const loadSettings = async () => {
		try {
			const { data } = await getOrganizationSettings();
			setSettings(data);
		} catch (err) {}
	};

	useEffect(() => {
		if (!selectedProfile) return;
		loadSettings();
	}, [selectedProfile]);

	return <SettingsContext.Provider value={{ settings }}>{props.children}</SettingsContext.Provider>;
};

export const useSettings = () => {
	const context = useContext(SettingsContext);

	if (!context) {
		throw new Error('useSettings must be used within SettingsProvider');
	}

	return context;
};
