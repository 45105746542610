import { Identity } from '../../context/permissions/identity';
import { apiClient } from '../client';

export const getIdentities = async (accessToken: string): Promise<Identity[]> => {
	try {
		const { data } = await apiClient('guardian').get<Identity[]>(`organization/identities`, {
			headers: { Authorization: `Bearer ${accessToken}` },
		});
		return data;
	} catch (e) {
		throw e;
	}
};
