import { useEffect, useRef, useState } from 'react';

type PDFPageProps = {
	page: any;
	onMeasure: (scale: number) => void;
};

export const PDFPage = ({ page, onMeasure }: PDFPageProps) => {
	const canvasRef = useRef<any>();

	const [width, setWidth] = useState<number>(0);
	const widthRef = useRef<number>(width);

	const [height, setHeight] = useState<number>(0);

	const measure = () => {
		if (canvasRef.current) {
			const scale = canvasRef.current.clientWidth / widthRef.current;
			onMeasure(scale);
		}
	};

	const render = async () => {
		const _page = await page;

		const context = canvasRef.current.getContext('2d');
		const viewport = _page.getViewport({ scale: 1, rotation: 0 });

		setWidth(viewport.width);
		setHeight(viewport.height);

		await _page.render({
			canvasContext: context,
			viewport,
		}).promise;

		measure();

		window.addEventListener('resize', measure);
	};

	useEffect(() => {
		render();

		return () => {
			window.removeEventListener('resize', measure);
		};
	}, [page]);

	useEffect(() => {
		widthRef.current = width;
	}, [width]);

	return (
		<div>
			<canvas
				ref={canvasRef}
				className="max-w-full"
				style={{ width: `${width}px` }}
				width={width}
				height={height}
			/>
		</div>
	);
};
