import { ReactNode } from 'react';
import { usePermissions } from '../../context/permissions/use-permissions';

export type PermissionV2 =
	| 'feed:read'
	| 'feed:create'
	| 'feed:update'
	| 'feed:delete'
	| 'athlete:medical-examination:notification'
	| 'athlete:medical-profile:read'
	| 'staff:medical-profile:read'
	| 'table:export'
	| 'table:customization'
	| 'athlete:send-communication'
	| 'staff:send-communication'
	| 'website:read'
	| 'website:sponsors'
	| 'website:news'
	| 'modules:bulk-generation'
	| 'enrolls:settings:general'
	| 'enrolls:settings:fields'
	| 'enrolls:settings:privacy'
	| 'enrolls:settings:attachments'
	| 'enrolls:athlete:write'
	| 'enrolls:staff:write'
	| 'associates:read'
	| 'archive:athlete:read'
	| 'archive:athlete:write'
	| 'archive:staff:read'
	| 'archive:staff:write'
	| 'associates:write'
	| 'club:general:read'
	| 'club:general:write'
	| 'club:signature:read'
	| 'club:signature:write'
	| 'club:social:read'
	| 'club:social:write'
	| 'club:documents:read'
	| 'club:documents:write'
	| 'club:calendar:read'
	| 'club:calendar:write'
	| 'club:notifications:read'
	| 'club:notes:read'
	| 'club:notes:write'
	| 'athlete:notes:read'
	| 'athlete:notes:write'
	| 'staff:notes:read'
	| 'staff:notes:write'
	| 'historical:athlete:read'
	| 'historical:financial:read'
	| 'historical:staff:read'
	| 'club:billing-info:read'
	| 'club:billing-info:write'
	| 'athlete:criteria:read'
	| 'athlete:criteria:write'
	| 'athlete:anagrafica:read'
	| 'athlete:anagrafica:write'
	| 'athlete:medical:read'
	| 'athlete:medical:write'
	| 'staff:medical:read'
	| 'staff:medical:write'
	| 'athlete:documents:read'
	| 'athlete:documents:write'
	| 'staff:documents:read'
	| 'staff:documents:write'
	| 'staff:anagrafica:read'
	| 'staff:anagrafica:write'
	| 'athlete:calendar:read'
	| 'athlete:calendar:write'
	| 'athlete:sport:read'
	| 'athlete:sport:write'
	| 'group:read'
	| 'group:write'
	| 'financial:athlete:read'
	| 'financial:athlete:write'
	| 'financial:staff:read'
	| 'financial:staff:write'
	| 'financial:general:read'
	| 'financial:general:write'
	| 'financial:invoices'
	| 'organization:profiles:read'
	| 'organization:profiles:write'
	| 'organization:profiles:collaborators:read'
	| 'organization:profiles:collaborators:write'
	| 'agreements:read'
	| 'agreements:write';

type PermissionWrapperProps = {
	permission: PermissionV2;
	type?: 'fullPage' | 'section';
	style?: React.CSSProperties;
	popupStyle?: React.CSSProperties;
	children?: ReactNode;

	/**
	 * @default true
	 */
	hideContent?: boolean;
};

export const PermissionWrapper: React.FC<PermissionWrapperProps> = ({ ...props }) => {
	const { identity } = usePermissions();

	const permission = identity?.permissions[props.permission];

	if (permission === 'allowed' || !props.permission) {
		return <>{props.children}</>;
	}

	// if (permission === 'need-plan:plus' || permission === 'need-plan:premium') {
	// return <div {...props} />;
	// }

	return <></>;
};
