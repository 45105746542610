import { TObjTranche } from '@golee/gle-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getTranches } from '../../api/financial/tranches';
import { useOrgPerson } from '../org-person/use-org-person';
import { useProfiles } from '../profiles/use-profiles';
import { useSeason } from '../season';

type TTrancheContext = {
	tranches: TObjTranche[];
	updateContext: () => void;
};

export const TrancheContext = createContext<TTrancheContext | undefined>(undefined);

export const TrancheProvider = (props: { children: React.ReactNode; fallback: React.ReactNode }) => {
	const { selectedProfile } = useProfiles();
	const { orgPerson } = useOrgPerson();
	const { selectedSeason } = useSeason();

	const [tranches, setTranches] = useState<TObjTranche[]>();

	useEffect(() => {
		void retrieveTranches();
	}, [selectedProfile, selectedSeason]);

	const updateContext = () => {
		void retrieveTranches();
	};

	const retrieveTranches = async () => {
		if (!selectedSeason || !orgPerson) return;
		try {
			const resp = await getTranches({
				season: selectedSeason,
				ref_org_person: orgPerson.orgPersonId,
			});
			setTranches(resp.data);
		} catch (err) {
			//TODO: navigate to error page
		}
	};

	if (!tranches) return <>{props.fallback}</>;

	return (
		<TrancheContext.Provider
			value={{
				tranches,
				updateContext,
			}}>
			{props.children}
		</TrancheContext.Provider>
	);
};

export const useTranches = () => {
	const context = useContext(TrancheContext);

	if (!context) {
		throw new Error('useTranches must be used within TrancheProvider');
	}

	return { ...context, trancheToCashCount: context.tranches.filter(t => !t.cashed).length };
};
