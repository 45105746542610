import { apiClient } from '../../../api/client';
import { OrgPersonConsentStatus } from './types';

export const saveConsents = (data: {
	consents: { consentId: string; status: OrgPersonConsentStatus }[];
	season: string;
}) => {
	return apiClient('people').post<void>(`org-person-consents`, data);
};

export const initSignature = (data: { consentId: string; orgPersonId: string }) => {
	return apiClient('people').post<{ documentId: string }>(`org-person-consents/init-with-signature`, data);
};
