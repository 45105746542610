import { TObjTranche } from "@golee/gle-types";
import moment from "moment";

export const sortedTranches = (tranches: TObjTranche[]): TObjTranche[] => {
    const notCashed = tranches.filter(tranche => !tranche.cashed);
    const cashed = tranches.filter(tranche => tranche.cashed);

    notCashed.sort((a, b) => {
        if (a.expected_cashed_date && b.expected_cashed_date) {
            if (
                moment(a.expected_cashed_date, 'DD/MM/YYYY').isBefore(moment(b.expected_cashed_date, 'DD/MM/YYYY'))
            ) {
                return -1;
            } else {
                return 1;
            }
        }
        return 0;
    });

    cashed.sort((a, b) => {
        if (a.cashed_date && b.cashed_date) {
            return moment(b.cashed_date, 'DD/MM/YYYY').diff(moment(a.cashed_date, 'DD/MM/YYYY'));
        }
        return 0;
    });

    return [...notCashed, ...cashed];
};