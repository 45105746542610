import { useEffect, useState } from 'react';
import { apiClient } from '../../api/client';
import { useCache } from '../../context/cache';
import { useOrgPerson } from '../../context/org-person/use-org-person';
import { CustomField } from './types';

export const useCustomFields = () => {
	const cache = useCache<CustomField[]>();

	const { organization } = useOrgPerson();
	const [customFields, setCustomFields] = useState<CustomField[]>([]);

	const loadCustomFields = async () => {
		if (!organization?.sports?.length) {
			return;
		}

		const cachedFields = cache.get(`custom-fields`);

		if (cachedFields) {
			setCustomFields(cachedFields);
			return;
		}

		try {
			const { data } = await apiClient('people').get<CustomField[]>(
				`custom-fields?sports=${organization.sports.join(',')}`
			);

			setCustomFields(data.filter(_ => !!_));
			cache.set(`custom-fields`, data);
		} catch (err) {}
	};

	useEffect(() => {
		if (organization) {
			loadCustomFields();
		}
	}, [organization]);

	return { customFields };
};
