import { useContext } from 'react';
import { PermissionsContext, PermissionsContextType } from './permissions-context';

export const usePermissions: () => PermissionsContextType & {} = () => {
	const context = useContext(PermissionsContext);

	if (!context) {
		throw new Error('usePermissions must be used within PermissionsProvider');
	}

	return context;
};


