import { Heading } from '@/components/heading';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/tabs';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { PeriodProvider } from './context/period-context';
import { EventList } from './list/event-list';
import { StatisticsTab } from './statistics/statistics';

export const Calendar = () => {
	const { orgPerson } = useOrgPerson();

	return (
		<div className={'hide-scrollbar pb-5'}>
			<Heading className="mb-5">Calendario</Heading>

			<PeriodProvider>
				<Tabs defaultValue="calendar">
					<TabsList className="grid w-full grid-cols-2">
						<TabsTrigger value="calendar">Eventi</TabsTrigger>
						<TabsTrigger value="stats">Statistiche</TabsTrigger>
					</TabsList>
					<TabsContent value="calendar">
						<EventList orgPersonId={orgPerson.orgPersonId} organizationId={orgPerson.organizationId} />
					</TabsContent>
					<TabsContent value="stats">
						<StatisticsTab />
					</TabsContent>
				</Tabs>
			</PeriodProvider>
		</div>
	);
};
