import { createContext, PropsWithChildren, useState } from 'react';

export type CacheContextType<T = any> = {
	cache: ReadonlyMap<string, T>;
	get: (key: string) => T | undefined;
	set: (key: string, value: T) => void;
	invalidate: (key: string) => void;
};

export const CacheContext = createContext<CacheContextType | undefined>(undefined);

export const CacheProvider: React.FC<PropsWithChildren> = props => {
	const [cache, setCache] = useState<Map<string, any>>(new Map());

	const invalidate = async (key: string) => {
		const _ = cache;
		_.delete(key);
		setCache(new Map(_));
	};

	const get = (key: string) => {
		return cache.get(key);
	};

	const set = (key: string, value: any) => {
		const _ = cache;
		_.set(key, value);
		setCache(new Map(_));
	};

	return (
		<CacheContext.Provider
			value={{
				cache: cache as ReadonlyMap<string, any>,
				get,
				set,
				invalidate,
			}}>
			{props.children}
		</CacheContext.Provider>
	);
};
