export const CalendarImg = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 853.11745 565">
			<path
				d="M993.2113,545.61c-6.16016,6.73-13.79981,11.74-22.08985,15.63h-.01025c-.77979.37-1.56006.73-2.3501,1.07h-.00977l-.01025.01-.00977-.01h-.00976a.00988.00988,0,0,1-.01025.01H968.701l-.00976-.01c-.00977.01-.00977.01-.02,0l-.00977.01-.01025-.01h-.00977c-.01025.01-.01025.01-.02,0h-.01025a139.77437,139.77437,0,0,1-17.06006,6.1,236.6512,236.6512,0,0,1-105.10986,5.49c-.66993-.12-1.33008-.24-2-.36V492.43c.66015-.28,1.33007-.56,2-.83q6.55516-2.715,13.27-4.99,9.65991-3.3,19.58984-5.66a212.16658,212.16658,0,0,1,66.04-5.34q6.0454.45,12.06006,1.3c8.39013,1.17005,17.18994,3.21,24.93017,6.75h.00977c1.13037.53,2.24023,1.08,3.34033,1.67005,6.87988,3.73,12.67969,8.86,16.21973,15.89a30.57258,30.57258,0,0,1,2.72021,7.99v.02c.19971.96.33985,1.93.44971,2.89C1006.33141,523.96,1001.42126,536.63,993.2113,545.61Z"
				transform="translate(-173.44128 -167.5)"
				fill="#f2f2f2"
			/>
			<path
				d="M1005.07116,512.12a1.35827,1.35827,0,0,1-.29981.04q-25.69482,1.125-51.32031,3.34h-.02978c-.06983.01-.12989.01-.19971.02Q926.91149,517.8,900.7113,521.23c-.73975.1-1.47022.2-2.2002.29q-18.11939,2.415-36.16992,5.36-7.96509,1.305-15.8999,2.72c-.66993.12-1.33008.23-2,.36v-3.03c.66992-.13,1.33984-.24,2-.36q26.43016-4.68,53.04-8.17,6.69-.9,13.39991-1.7,19.37988-2.34,38.81982-4.04c.9502-.08,1.89014-.16,2.84033-.24q24.88477-2.115,49.83985-3.21a.939.939,0,0,1,.24023.02C1006.22155,509.37,1006.56139,511.77,1005.07116,512.12Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M969.49743,559.36319c-21.33986.15682-42.36351-8.89183-57.45793-23.86936a77.82614,77.82614,0,0,1-11.18417-14.07c-1.01743-1.63171-3.39758.16767-2.38888,1.78539,11.7227,18.80042,31.24951,32.19306,52.77823,37.11736a80.81149,80.81149,0,0,0,18.63625,1.99418c1.91362-.01407,1.51718-2.97155-.3835-2.95758Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M879.30114,480.95a1.6922,1.6922,0,0,1-1.25,1.13,65.2198,65.2198,0,0,0-14.07959,5.5,68.40608,68.40608,0,0,0-17.53027,13.25c-.27.27-.52979.54-.77979.82-.42041.44-.82031.89-1.22021,1.35v-4.36c.64013-.68,1.31005-1.35,2-1.99a71.91209,71.91209,0,0,1,13.27-10.04Q869.37121,483.31,879.30114,480.95Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M984.1,482.87181a69.40228,69.40228,0,0,0-32.42171,28.58557,1.49955,1.49955,0,0,0,.79261,1.95385,1.5276,1.5276,0,0,0,1.95385-.79261A66.04852,66.04852,0,0,1,985.221,485.635c1.76525-.75416.64785-3.51882-1.12092-2.76316Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M1025.02135,397.94c-2.32031,8.82-6.77,16.81-12.2998,24.1l-.01025.01c-.51026.68-1.04,1.37-1.58985,2.03v.01a139.27064,139.27064,0,0,1-12.41015,13.39,233.4288,233.4288,0,0,1-53.37012,38.13c-30.66992,16.02-64.94971,25.49-98.8999,26.03-.25977.01-.52.01-.77979.01-.41016.01-.82031.01-1.22021.01V456.21c.6499-1.24,1.31982-2.48,2-3.71a219.52414,219.52414,0,0,1,32.03027-43.82c.2998-.33.60986-.66.91992-.98q7.01953-7.41,14.71973-14.11a210.81877,210.81877,0,0,1,67.29-39.85c7.97021-2.86,16.71-5.15,25.21-5.6,1.25-.07,2.49023-.1,3.74023-.09,7.83008.11,15.33985,1.96,21.75,6.54a30.99854,30.99854,0,0,1,6.12012,5.81c.61963.77,1.18994,1.56,1.73975,2.37v.01C1026.58141,372.68,1028.12145,386.17,1025.02135,397.94Z"
				transform="translate(-173.44128 -167.5)"
				fill="#f2f2f2"
			/>
			<path
				d="M1019.9613,362.77v.01a1.46123,1.46123,0,0,1-.23975.17q-22.20043,12.915-43.84033,26.77c-.02.01-.02979.02-.04981.03a1.73947,1.73947,0,0,1-.18017.11q-22.24512,14.25-43.85987,29.46c-.60009.42-1.21.85-1.81982,1.28q-14.9253,10.545-29.54,21.54-27.60059,20.79-53.99023,43.12c-.66993.56-1.33008,1.13-2,1.7v-3.89c.66015-.57,1.33007-1.14,2-1.7q10.11035-8.535,20.3999-16.83c2.05029-1.65,4.10986-3.3,6.16992-4.93q27.45043-21.855,56.13037-42.08h.00977q5.64037-3.975,11.2998-7.88,16.08033-11.07,32.52051-21.61c.78955-.51,1.58984-1.02,2.38965-1.53q21.0454-13.44,42.64013-26a1.48864,1.48864,0,0,1,.22022-.11C1019.72155,359.8,1021.13122,361.78,1019.9613,362.77Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M1010.4094,421.13341c-18.82492,10.05171-41.64595,11.80452-61.97038,5.55268a77.82663,77.82663,0,0,1-16.44008-7.26456c-1.659-.97236-2.93089,1.72674-1.28615,2.69076,19.11437,11.20348,42.62774,13.99287,63.9802,8.35308a80.81169,80.81169,0,0,0,17.42988-6.891c1.6881-.90137-.03679-3.33625-1.71347-2.441Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M869.56249,457.72184a68.55277,68.55277,0,0,1-.95241-15.86924,72.31889,72.31889,0,0,1,10.7807-34.15065q7.02818-7.4075,14.72332-14.11226a1.70457,1.70457,0,0,1-.58619,1.58483,65.45128,65.45128,0,0,0-9.91171,11.40756,69.12172,69.12172,0,0,0-11.08214,50.89232,1.21242,1.21242,0,0,1-.31374,1.14283A1.62455,1.62455,0,0,1,869.56249,457.72184Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M987.809,346.61236a69.40222,69.40222,0,0,0-15.43281,40.37487,1.49954,1.49954,0,0,0,1.60951,1.36207,1.52761,1.52761,0,0,0,1.36207-1.60951,66.04848,66.04848,0,0,1,14.73742-38.20118c1.21291-1.48785-1.06086-3.41707-2.27619-1.92625Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M884.25133,379.47v.01c-.06982.86005-.1499,1.71-.24023,2.56v.02a141.29936,141.29936,0,0,1-3.25,17.96c-.69971,2.9-1.46,5.78-2.28955,8.66-.1001.37-.21045.74-.32031,1.1v.01a229.52292,229.52292,0,0,1-8.73975,24.65,238.91849,238.91849,0,0,1-22.97021,42.22c-.64991.97-1.31983,1.94-2,2.9V322.96c.66992-.04,1.33007-.04,2-.02q.47973,0,.96.03a30.32184,30.32184,0,0,1,8.28027,1.61005c.93994.31,1.84961.66,2.75,1.05,10.90967,4.78,19.48,15.32,23.19971,26.91C884.42126,361.22,884.98131,370.35,884.25133,379.47Z"
				transform="translate(-173.44128 -167.5)"
				fill="#f2f2f2"
			/>
			<path
				d="M858.43151,325.63a1.07625,1.07625,0,0,1-.11035.28q-6.07471,11.80509-11.87988,23.73c-.66993,1.37-1.33985,2.75-2,4.12v-6.8q.99023-2.04006,2-4.08,4.48535-9.09,9.12011-18.1a1.30351,1.30351,0,0,1,.12012-.2C856.60143,323.26,858.88122,324.16,858.43151,325.63Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M884.57116,381.08c-.17969.32-.35986.63995-.56006.96a79.81637,79.81637,0,0,1-10.41992,14.24,85.91629,85.91629,0,0,1-27.1499,19.77c-.66016.32-1.33008.62-2,.9V413.7c.66992-.3,1.33984-.61,2-.93a80.866,80.866,0,0,0,35.37011-32.82,1.64488,1.64488,0,0,1,2.43994-.47A1.15274,1.15274,0,0,1,884.57116,381.08Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fff"
			/>
			<path
				d="M846.44128,200.5v76h-2v-.81h-669v.81h-2v-76a33.03244,33.03244,0,0,1,33-33h607A33.03244,33.03244,0,0,1,846.44128,200.5Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fa6762"
			/>
			<path
				d="M813.44128,167.5h-607a33.03244,33.03244,0,0,0-33,33v434a33.03244,33.03244,0,0,0,33,33h607a33.03244,33.03244,0,0,0,33-33v-434A33.03244,33.03244,0,0,0,813.44128,167.5Zm31,467a31.03964,31.03964,0,0,1-31,31h-607a31.03963,31.03963,0,0,1-31-31v-434a31.03963,31.03963,0,0,1,31-31h607a31.03964,31.03964,0,0,1,31,31Z"
				transform="translate(-173.44128 -167.5)"
				fill="#3f3d56"
			/>
			<circle cx="136" cy="54.5" r="20" fill="#fff" />
			<circle cx="537" cy="54.5" r="20" fill="#fff" />
			<path
				d="M367.22591,445.00191H248.24968a19.033,19.033,0,0,1-19.01145-19.01144V369.75784a19.033,19.033,0,0,1,19.01145-19.01144H367.22591a19.033,19.033,0,0,1,19.01145,19.01144v56.23263A19.033,19.033,0,0,1,367.22591,445.00191Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fa6762"
			/>
			<path
				d="M569.62448,445.00191H450.64824a19.033,19.033,0,0,1-19.01144-19.01144V369.75784a19.033,19.033,0,0,1,19.01144-19.01144H569.62448a19.033,19.033,0,0,1,19.01144,19.01144v56.23263A19.033,19.033,0,0,1,569.62448,445.00191Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fa6762"
			/>
			<path
				d="M569.62448,590.29329H450.64824a19.60963,19.60963,0,0,1-19.58755-19.58755V514.47255A19.60962,19.60962,0,0,1,450.64824,494.885H569.62448A19.60962,19.60962,0,0,1,589.212,514.47255v56.23319A19.60962,19.60962,0,0,1,569.62448,590.29329Z"
				transform="translate(-173.44128 -167.5)"
				fill="#f2f2f2"
			/>
			<path
				d="M366.83575,590.29329H247.85951A19.60963,19.60963,0,0,1,228.272,570.70574V514.47255A19.60962,19.60962,0,0,1,247.85951,494.885H366.83575a19.60962,19.60962,0,0,1,19.58755,19.58754v56.23319A19.60963,19.60963,0,0,1,366.83575,590.29329Z"
				transform="translate(-173.44128 -167.5)"
				fill="#f2f2f2"
			/>
			<path
				d="M772.023,590.29329H653.0468a19.60962,19.60962,0,0,1-19.58754-19.58755V514.47255A19.60962,19.60962,0,0,1,653.0468,494.885H772.023a19.60962,19.60962,0,0,1,19.58755,19.58754v56.23319A19.60963,19.60963,0,0,1,772.023,590.29329Z"
				transform="translate(-173.44128 -167.5)"
				fill="#f2f2f2"
			/>
			<path
				d="M772.023,445.115H653.0468a19.60962,19.60962,0,0,1-19.58754-19.58754V369.29426a19.60962,19.60962,0,0,1,19.58754-19.58755H772.023a19.60963,19.60963,0,0,1,19.58755,19.58755v56.23319A19.60962,19.60962,0,0,1,772.023,445.115Z"
				transform="translate(-173.44128 -167.5)"
				fill="#f2f2f2"
			/>
			<circle cx="134.29652" cy="230.37416" r="32.26184" fill="#fff" />
			<path
				d="M304.5399,410.1813a3.34586,3.34586,0,0,1-2.01323-.66875l-.036-.027-7.58175-5.80486a3.3684,3.3684,0,1,1,4.09732-5.34756l4.91089,3.7656,11.6052-15.13487a3.36827,3.36827,0,0,1,4.72263-.62342l-.07215.098.074-.09655a3.3722,3.3722,0,0,1,.62341,4.72267l-13.6505,17.80224A3.36974,3.36974,0,0,1,304.5399,410.1813Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fa6762"
			/>
			<circle cx="337.27119" cy="229.91085" r="32.26184" fill="#fff" />
			<path
				d="M507.51457,409.718a3.3459,3.3459,0,0,1-2.01324-.66874l-.036-.027-7.58176-5.80486a3.3684,3.3684,0,1,1,4.09733-5.34756l4.91088,3.7656,11.6052-15.13488a3.3683,3.3683,0,0,1,4.72264-.62341l-.07215.098.074-.09654a3.37217,3.37217,0,0,1,.62341,4.72266l-13.65049,17.80224A3.36974,3.36974,0,0,1,507.51457,409.718Z"
				transform="translate(-173.44128 -167.5)"
				fill="#fa6762"
			/>
			<path
				d="M728.75351,549.64979a10.74269,10.74269,0,0,0-2.18282-16.32737l-18.01117-96.17273-21.90458,8.12263,24.59219,91.98054a10.80091,10.80091,0,0,0,17.50638,12.39693Z"
				transform="translate(-173.44128 -167.5)"
				fill="#a0616a"
			/>
			<path
				d="M553.96858,402.90038a11.40614,11.40614,0,0,1,.02187,1.79608l42.59,32.77955,12.0777-4.95957,9.86722,17.24911-20.528,10.85781a8.66955,8.66955,0,0,1-10.27652-1.62745l-42.571-43.8892a11.37563,11.37563,0,1,1,8.81868-12.20633Z"
				transform="translate(-173.44128 -167.5)"
				fill="#a0616a"
			/>
			<polygon
				points="480.028 552.849 467.769 552.849 461.936 515.561 480.031 515.562 480.028 552.849"
				fill="#a0616a"
			/>
			<path
				d="M656.59617,732.23337l-39.53051-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
				transform="translate(-173.44128 -167.5)"
				fill="#2f2e41"
			/>
			<polygon
				points="526.028 552.849 513.769 552.849 507.936 515.561 526.031 515.562 526.028 552.849"
				fill="#a0616a"
			/>
			<path
				d="M702.59617,732.23337l-39.53051-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
				transform="translate(-173.44128 -167.5)"
				fill="#2f2e41"
			/>
			<path
				d="M638.6643,712.9793a4.49017,4.49017,0,0,1-4.47485-4.07226L623.50586,565.21758l.50488-.041,73.521-6.043.01978.52246L703.396,708.50664a4.49964,4.49964,0,0,1-4.49707,4.668H684.48339a4.475,4.475,0,0,1-4.44751-3.81543L660.45971,589.11407a.5.5,0,0,0-.99414.07226L658.57763,707.91a4.50454,4.50454,0,0,1-4.26318,4.46192l-15.40869.60058C638.82495,712.97735,638.74414,712.9793,638.6643,712.9793Z"
				transform="translate(-173.44128 -167.5)"
				fill="#2f2e41"
			/>
			<circle cx="477.7719" cy="191.56438" r="24.56103" fill="#a0616a" />
			<path
				d="M655.96792,574.38164a121.03726,121.03726,0,0,1-31.76839-4.34179,4.51046,4.51046,0,0,1-3.2358-4.68653c3.30645-49.69336,4.08385-88.25683-2.86906-114.939-2.96342-11.37207-1.618-23.34912,3.69108-32.85986,7.98992-14.313,22.67547-23.02442,38.34016-22.72364h0q1.12287.02124,2.26805.08008c23.7729,1.22412,42.29735,22.73047,41.29428,47.9419l-4.78177,120.16845a4.43993,4.43993,0,0,1-2.81538,4.04395A114.24513,114.24513,0,0,1,655.96792,574.38164Z"
				transform="translate(-173.44128 -167.5)"
				fill="#3f3d56"
			/>
			<path
				d="M622.13643,459.64084l-18.39762-22.54449a5.76149,5.76149,0,0,1,1.51427-8.59185l24.92148-14.85005a16.00071,16.00071,0,0,1,20.16015,24.85261l-19.47729,21.37306a5.7615,5.7615,0,0,1-8.721-.23928Z"
				transform="translate(-173.44128 -167.5)"
				fill="#3f3d56"
			/>
			<path
				d="M680.01136,456.81194a5.7553,5.7553,0,0,1-3.16409-3.60611l-8.282-27.70526A16.00071,16.00071,0,0,1,697.50865,411.849L713.525,436.03743a5.7615,5.7615,0,0,1-2.36144,8.3986l-26.35426,12.3361A5.755,5.755,0,0,1,680.01136,456.81194Z"
				transform="translate(-173.44128 -167.5)"
				fill="#3f3d56"
			/>
			<path
				d="M663.35014,379.21724l-18.206-4.16705c-1.87794-.42983-4.13413-1.24991-4.39457-3.15873-.35-2.56513,3.34149-4.35213,3.001-6.91854-.32983-2.48578-3.69254-2.80615-6.09035-3.53991a9.11065,9.11065,0,0,1-5.67236-11.34023c-2.59437,3.656-8.52155,3.96913-11.88655,1.00712s-4.01043-8.33613-1.98963-12.33777a14.28744,14.28744,0,0,1,10.724-7.2405,22.61663,22.61663,0,0,1,13.02306,2.42787c.26664-2.83339,3.80421-3.98207,6.65-4.00735a28.43074,28.43074,0,0,1,26.64315,19.4463c3.492,11.25212,1.15619,23.58635-8.6142,30.16993Z"
				transform="translate(-173.44128 -167.5)"
				fill="#2f2e41"
			/>
			<path
				d="M846.72868,732.5h-381a1,1,0,1,1,0-2h381a1,1,0,1,1,0,2Z"
				transform="translate(-173.44128 -167.5)"
				fill="#3f3d56"
			/>
		</svg>
	);
};
