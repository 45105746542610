import { ReactNode } from 'react';

type TextWithLinksRendererProps = {
	text: string;
};

export const TextWithLinksRenderer: React.FC<TextWithLinksRendererProps> = props => {
	const urlRegex = /https?:\/\/[^\s]+/g;

	const content: ReactNode[] = props.text
		.split(urlRegex)
		.reduce((acc: ReactNode[], part: string, index: number): ReactNode[] => {
			const parsedPart = <span dangerouslySetInnerHTML={{ __html: part.replace(/\n/g, '<br />') }}></span>;

			if (index === 0) {
				return [parsedPart];
			}

			const match = (props.text.match(urlRegex) ?? [])[index - 1];

			const linkElement = (
				<a
					href={match}
					target="_blank"
					rel="noopener noreferrer"
					key={index}
					className="cursor-pointer text-sky-500">
					{match}
				</a>
			);

			return [...acc, linkElement, parsedPart];
		}, []);

	return <p>{content}</p>;
};
