import { useEffect, useState } from 'react';
import { apiClient } from '../../../api/client';
import { useProfiles } from '../../../context/profiles/use-profiles';
import { useBoolean } from '../../../hooks/use-boolean';
import { Notification } from './types';

const getNotificationsList = () => apiClient('hermes').get(`notifications/in-app`, {});

export const useNotifications = () => {
	const { selectedProfile } = useProfiles();

	const [notificationsList, setNotificationsList] = useState<Notification[]>([]);
	const [isLoading, setIsLoading] = useBoolean();

	const getNotifications = async () => {
		if (!selectedProfile?._id) return;

		try {
			setIsLoading(true);
			const { data } = await getNotificationsList();
			setNotificationsList(data.reverse());
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getNotifications();
	}, []);

	return { notificationsList, isLoading };
};
