import { Checkbox, CheckboxProps } from '@gle/base-ui.inputs.checkbox';
import { ErrorMessage, FieldConfig, useField } from 'formik';
import styled from 'styled-components';

type Props = Omit<FieldConfig<any>, 'children'> & { onChange?: CheckboxProps['onChange'] } & Pick<
		CheckboxProps,
		'label' | 'disabled' | 'render' | 'style'
	>;

const Wrapper = styled.div`
	width: 100%;
	height: 38px;
	display: flex;
	flex-direction: column;
	gap: 5px;

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);
	}
`;

export const CheckboxField = ({ onChange, ...props }: Props) => {
	const [field, meta, helpers] = useField<boolean>(props);

	return (
		<Wrapper className={`${meta.touched && meta.error ? 'error' : ''} `}>
			<Checkbox
				{...props}
				{...field}
				onChange={value => {
					helpers.setValue(value);
					onChange?.(value);
				}}
			/>
			<ErrorMessage name={props.name} component="div" className="form-field-error" />
		</Wrapper>
	);
};
