import { Button } from '@/components/button';
import {
	Drawer,
	DrawerContent,
	DrawerDescription,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from '@/components/drawer';
import { useToast } from '@/components/toast';
import { useState } from 'react';
import { deleteOrgPersonParent } from '../../../../../context/org-person/api';

export const DeleteParentButton = (props: {
	parentId: string;
	orgPersonId: string;
	season: string;
	onComplete?: () => void;
}) => {
	const { toast } = useToast();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const onDelete = async () => {
		setIsLoading(true);
		try {
			await deleteOrgPersonParent(props.orgPersonId, props.parentId, props.season);
			props.onComplete?.();
		} catch (err) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Drawer open={isOpen} onOpenChange={setIsOpen}>
			<DrawerTrigger>
				<Button variant="destructive" type="button">
					Elimina
				</Button>
			</DrawerTrigger>
			<DrawerContent>
				<DrawerHeader>
					<DrawerTitle>Sei sicuro?</DrawerTitle>
					<DrawerDescription>Questa operazione non è reversibile</DrawerDescription>
				</DrawerHeader>

				<div className="mb-4 p-4">
					<div className="flex flex-col gap-2">
						<Button variant="secondary" disabled={isLoading} onClick={() => setIsOpen(false)}>
							Annulla
						</Button>
						<Button isLoading={isLoading} onClick={onDelete}>
							Si, elimina
						</Button>
					</div>
				</div>
			</DrawerContent>
		</Drawer>
	);
};
