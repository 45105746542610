import { ErrorMessage, FieldConfig, useField } from "formik";

import styled from "styled-components";

import { Input, InputProps } from "@gle/base-ui.inputs.input";
import { Text } from "@gle/base-ui.typography.text";

type Props = FieldConfig<any> & { warning?: string | undefined } & Omit<
    InputProps,
    "onFocus" | "value"
  >;

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  column-gap: 3px;
  row-gap: 3px;
  gap: 3px;

  &.h-auto {
    height: auto;
  }

  &.password-strength-enabled {
    height: 90px;
  }

  .form-field-error {
    color: var(--danger-color);
    font-size: var(--xs-size);

    &.password-strength-enabled {
      position: relative;
      top: -5px;
    }
  }

  &.error {
    label {
      color: var(--danger-color) !important;
    }
    input {
      border-color: var(--danger-color) !important;
    }
  }

  &.warning {
    input {
      border-color: var(--warning-color);
    }
  }
`;

export const InputField = ({
  style = {},
  onChange,
  onBlur,
  ...props
}: Props) => {
  const [{ onBlur: onFieldBlur, ...field }, meta, helpers] = useField(props);

  return (
    <Wrapper
      style={style}
      className={`${meta.touched && meta.error ? "error" : ""} ${
        props.type === "textarea" ? "h-auto" : ""
      } ${props.checkPasswordStrength ? "password-strength-enabled" : ""} ${
        props.warning ? "warning" : ""
      } ${props.className ? props.className : ""}
			`}
    >
      <Input
        {...props}
        {...field}
        onBlur={(e) => {
          onFieldBlur?.(e);
          onBlur?.(e);
        }}
        onChange={(e) => {
          helpers.setValue(e);
          onChange?.(e);
        }}
      />

      {meta.error ? (
        <ErrorMessage
          name={props.name}
          component="div"
          className={`form-field-error ${
            props.checkPasswordStrength && field.value
              ? "password-strength-enabled"
              : ""
          }`}
        />
      ) : (
        <>
          {props.warning && (
            <Text color="warning-dark" size="xs">
              {props.warning}
            </Text>
          )}
        </>
      )}
    </Wrapper>
  );
};
