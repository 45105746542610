import { Button } from '@/components/button';
import { ReadOnlyValue } from '@/components/read-only-input';
import { Skeleton } from '@/components/skeleton';
import { useToast } from '@/components/toast';
import { cn } from '@/lib/utils';
import { useConan } from '@golee/gle-conan-tracker';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Metadata } from '../../../../../api/people/metadata';
import { CustomInputFields } from '../../../../../components/custom-fields/custom-input-fields';
import { OrgPerson } from '../../../../../context/org-person';
import { useCustomFields } from '../../../../../hooks/custom-input-fields';
import { useMetadata } from '../../../../../hooks/use-metadata';

export type FormProps = {
	orgPerson: OrgPerson;
	onSave: () => void;
};

export const LegacySportForm = (props: FormProps) => {
	const { toast } = useToast();
	const { trackEvent } = useConan();
	const { customFields } = useCustomFields();

	const [isEdit, setIsEdit] = useState<boolean>(false);

	const {
		data,
		set: setMetadata,
		isLoading,
		isSaving,
	} = useMetadata({
		orgPersonId: props.orgPerson.orgPersonId,
		season: props.orgPerson.season,
	});

	//TODO: refactor this
	if (isLoading) {
		return (
			<div className="flex flex-col gap-4">
				<Skeleton className="h-5" />
				<Skeleton className="h-5" />
				<Skeleton className="h-5" />
				<Skeleton className="h-5" />
			</div>
		);
	}

	//TODO make dynamic validation schema based on custom fields
	const schema = Yup.object().shape({
		athlete: Yup.object().shape({
			technicalProfile: Yup.object().shape({
				'foot-n': Yup.number(),
			}),
		}),
	});

	const onSubmit = async (values: Metadata) => {
		await setMetadata(
			values,
			() => {
				toast({
					variant: 'destructive',
					title: 'Errore',
					description: 'Si è verificato un errore imprevisto, riprova più tardi',
				});
			},
			() => {
				toast({
					title: 'Salvataggio completato',
					description: 'I dati sono stati aggiornati con successo',
					variant: 'success',
				});
			}
		);

		void trackEvent('family.orgPerson-changed-data-from-app', {
			section: 'sport',
		});
		props.onSave();
		setIsEdit(false);
	};

	return (
		<Formik
			initialValues={{ ...data }}
			validationSchema={schema}
			enableReinitialize
			onSubmit={values => {
				onSubmit(values);
			}}>
			{formContext => {
				return (
					<Form>
						{!isEdit && (
							<div className="flex flex-col gap-4">
								<ReadOnlyValue
									value={props.orgPerson.groups?.map(group => group.groupName).join(', ') || '-'}
									type="text"
									label="Gruppi"
								/>

								<ReadOnlyValue
									type="text"
									label="Matricola"
									value={
										props.orgPerson.metadata.athlete?.technicalProfile?.registrationNumber || '-'
									}
								/>

								<ReadOnlyValue
									type="date"
									label="Data primo tesseramento"
									value={props.orgPerson.personMetadata.dates?.athleteMembershipFirstDate}
								/>

								<ReadOnlyValue
									type="date"
									label="Data scadenza tesseramento"
									value={props.orgPerson.personMetadata.dates?.athleteMembershipExpirationDate}
								/>
							</div>
						)}

						<div className={cn('my-4 flex flex-col', { 'gap-4': !isEdit })}>
							<CustomInputFields fields={customFields} formContext={formContext} isEditing={isEdit} />
						</div>

						{isEdit ? (
							<div className={'mt-4 flex justify-end gap-4'}>
								<Button
									variant="secondary"
									onClick={() => setIsEdit(false)}
									type="button"
									disabled={isSaving}>
									Annulla
								</Button>
								<Button type="submit" isLoading={isSaving}>
									Salva
								</Button>
							</div>
						) : (
							<div className="mt-4 flex justify-end">
								<Button variant="secondary" onClick={() => setIsEdit(true)} type="button">
									Modifica dati
								</Button>
							</div>
						)}
					</Form>
				);
			}}
		</Formik>
	);
};
