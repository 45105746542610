import { Select, SelectProps } from '@gle/base-ui.inputs.select';
import { Text } from '@gle/base-ui.typography.text';
import { useLocations } from '../../context/locations/context';
import SelectField, { SelectFieldProps } from '../../core/inputs/Select/Field';

export const CountrySelectField = (props: Omit<SelectFieldProps, 'options'>) => {
	const { countries } = useLocations();

	const italy = countries['ITA'];

	return (
		<SelectField
			{...props}
			name={props.name}
			formatGroupLabel={(group: any) => {
				return group.label ? (
					<Text size="xxs" weight="medium" color="accent" style={{ marginTop: -10 }}>
						{group.label}
					</Text>
				) : (
					<div style={{ marginTop: -10 }}></div>
				);
			}}
			options={[
				{ label: '', options: [{ label: italy.label, value: italy.key }] },
				{
					label: 'Estero',
					options: Object.keys(countries)
						.filter(key => key !== 'ITA')
						.map(key => ({
							value: countries[key].key,
							label: countries[key].label,
						})),
				},
			]}
		/>
	);
};

export const CountrySelect = (props: Omit<SelectProps, 'options'>) => {
	const { countries } = useLocations();

	const italy = countries['ITA'];

	return (
		<Select
			{...props}
			name={props.name}
			formatGroupLabel={(group: any) => {
				return group.label ? (
					<Text size="xxs" weight="medium" color="accent" style={{ marginTop: -10 }}>
						{group.label}
					</Text>
				) : (
					<div style={{ marginTop: -10 }}></div>
				);
			}}
			options={[
				{ label: '', options: [{ label: italy.label, value: italy.key }] },
				{
					label: 'Estero',
					options: Object.keys(countries)
						.filter(key => key !== 'ITA')
						.map(key => ({
							value: countries[key].key,
							label: countries[key].label,
						})),
				},
			]}
		/>
	);
};
