import { useEffect, useState } from "react";

export const useApi = <T>(
  apiFunction: ((params?: any) => Promise<any>) | undefined,
  params: any = {},
  dataDefaultValue?: any
) => {
  const [data, setData] = useState<T>(dataDefaultValue);
  const [error, setError] = useState<any | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const request = async (): Promise<void> => {
    if (error) {
      setError(undefined);
    }

    setIsLoading(true);

    if (!apiFunction) {
      setIsLoading(false);
      return;
    }

    try {
      const result = await apiFunction(params);
      setData(result.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    request();
  }, []);

  return {
    data,
    error,
    isLoading,
    request,
  };
};
