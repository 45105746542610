export const PostsImage = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 864.76204 663.11381">
			<path
				d="M960.14487,593.55791l27.02968-3.06463a87.77011,87.77011,0,0,0,7.53874-41.55186c-14.69281,20.71575-45.77605,20.38386-66.43116,35.16182a52.79388,52.79388,0,0,0-21.69162,40.29238l-8.92527,17.10462a88.46592,88.46592,0,0,0,70.244-23.50607,85.453,85.453,0,0,0,13.48384-16.666C970.75679,598.10738,960.14487,593.55791,960.14487,593.55791Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#f2f2f2"
			/>
			<path
				d="M939.56485,554.6326l22.82594-14.79769A87.77008,87.77008,0,0,0,950.607,499.28209c-3.91186,25.09419-31.8807,38.65966-43.77721,61.09835a52.79389,52.79389,0,0,0-1.44528,45.73744l-.36017,19.28986a88.4659,88.4659,0,0,0,52.38808-52.3664,85.45259,85.45259,0,0,0,4.6359-20.93029C951.09194,553.97186,939.56485,554.6326,939.56485,554.6326Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#f2f2f2"
			/>
			<circle cx="672.85327" cy="535.37799" r="81.90877" fill="#fff" />
			<path
				d="M840.47233,736.73017A82.90869,82.90869,0,1,1,923.381,653.821,83.00325,83.00325,0,0,1,840.47233,736.73017Zm0-163.81738A80.90869,80.90869,0,1,0,921.381,653.821,80.99981,80.99981,0,0,0,840.47233,572.91279Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#3f3d56"
			/>
			<path
				d="M834.33978,686.55655c-.17462,0-.34923-.00568-.52385-.0142a10.71124,10.71124,0,0,1-7.94017-4.12553L812.18739,664.8187a10.7156,10.7156,0,0,1,1.87821-15.03989l.49262-.38331a10.71454,10.71454,0,0,1,15.04273,1.87963,7.81622,7.81622,0,0,0,11.83714.57638L869.2308,622.51a10.73164,10.73164,0,0,1,15.152-.4117l.45571.43442a10.71438,10.71438,0,0,1,.40886,15.15062L842.11669,683.209A10.70455,10.70455,0,0,1,834.33978,686.55655Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#fa6761"
			/>
			<polygon
				points="630.005 640.052 618.645 641.317 607.882 597.245 624.647 595.377 630.005 640.052"
				fill="#a0616a"
			/>
			<path
				d="M872.46369,560.35705c-.35273-.35139-.73514-.69317-1.116-1.01813a103.62551,103.62551,0,0,0-9.17564-6.795c-3.59945-2.39805-7.64924-4.84575-12.23036-7.37294q-3.32578-1.83685-7.02953-3.75049-1.8555-.95907-3.81775-1.93194a1.92865,1.92865,0,0,0-.17166-.08936l-.37862-.19246-27.5679,22.75659-1.95836,1.53868,5.07832,6.26184s-.44214,4.9789-.4317,4.98133l-5.0412,12.57956-.05545.02561-17.96021,8.19739-18.50818,8.44943-9.94059,4.5408a19.09741,19.09741,0,0,0-10.02175,10.82853,19.85258,19.85258,0,0,0,.65394,15.33291c.22243.48608.47215.973.73716,1.44147l4.13064,25.97194a156.32434,156.32434,0,0,0,14.23117,44.70987l6.30891,24.31185.95852-.481,14.26874-7.14734,3.76984-1.88405.71168-16.33517c.08075-1.86169.09249-3.73388.02631-5.60221a72.007,72.007,0,0,0-.87545-9.1273l-8.18094-50.941q2.17758-.88771,4.29637-1.7837,9.82116-4.10947,18.59626-8.308c19.02619-9.0933,34.526-18.53329,47.18984-28.75882q5.33793-4.30179,10.01383-8.807,1.71862-1.64605,3.34576-3.32423c.2581-.2589.50981-.51382.75748-.77521a19.32833,19.32833,0,0,0,4.79649-17.8711A19.72121,19.72121,0,0,0,872.46369,560.35705Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<path
				d="M796.699,750.77969l-12.29683,1.35948-1.92955.21139,1.2556,7.3022L769.43773,773.613a4.62942,4.62942,0,0,0,4.0141,7.87728l20.294-3.47625,2.25763-7.82183,2.60406,6.98879,7.64427-1.30806L799.68928,750.452Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<path
				d="M808.40163,497.91508,818.931,486.74894,804.07821,454.2512l-2.20929-11.41461a8.63072,8.63072,0,0,0-14.09021-4.9129h0a8.63071,8.63071,0,0,0-1.12268,11.94446l8.20093,10.25113Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#a0616a"
			/>
			<path
				d="M818.931,481.74894l-12,12,2.29632,17.54523a13.95266,13.95266,0,0,0,10.06048,9.33112h0a13.95268,13.95268,0,0,0,15.19611-6.39465L861.47543,467.966a14.24249,14.24249,0,0,0-4.59235-19.36008h0a14.24259,14.24259,0,0,0-20.40241,5.74969L820.006,489.45985Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#e6e6e6"
			/>
			<path
				d="M806.84244,658.82554c-.62.63117-1.24188,1.25479-1.87106,1.88035-1.25107,1.24919-2.52031,2.48713-3.79313,3.71034-.64008.61258-1.28753,1.22692-1.92937,1.83212l.00525.02194-.04243.01794-.01294.01089-1.0675.55127-2.65657-3.91277-1.22517-1.80185-1.73183-2.553-3.17238-4.66934L772.642,629.32719l14.97988-7.73257,1.17969,2.29564.00737-.00176.58347,1.13756,10.95486,21.22606,2.237,4.3401.07027.13112,1.34381,2.5912,1.16,2.24578,1.2932,2.51Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#a0616a"
			/>
			<path
				d="M803.74713,650.85653l-10.42758,6.65777-1.6371,1.043,4.35565,5.99392-6.64276,18.84095a4.62942,4.62942,0,0,0,7.084,5.28971l16.664-12.09315-1.4345-8.01375,5.42645,5.11645,6.27751-4.554-17.12865-19.89723Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<path
				d="M441.68922,282.17819l7.98486-26.00457a87.77012,87.77012,0,0,0-35.08391-23.50551c13.12443,21.74328.40683,50.10771,5.70643,74.9459a52.7939,52.7939,0,0,0,28.27718,35.97783l12.11706,15.0135a88.466,88.466,0,0,0,6.50218-73.78667,85.45272,85.45272,0,0,0-9.89442-19.01759C450.0981,274.2661,441.68922,282.17819,441.68922,282.17819Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#f2f2f2"
			/>
			<path
				d="M397.78389,285.50076l-4.45075-26.83629a87.77006,87.77006,0,0,0-41.8846-5.39168c21.444,13.60791,22.71122,44.66709,38.53195,64.5348a52.79388,52.79388,0,0,0,41.35469,19.59061l17.541,8.03374a88.4659,88.4659,0,0,0-27.08772-68.942,85.45282,85.45282,0,0,0-17.33741-12.60883C401.78156,274.6689,397.78389,285.50076,397.78389,285.50076Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#f2f2f2"
			/>
			<path
				d="M550.60587,206.68621l8.20093-10.25113a8.63071,8.63071,0,0,0-1.12268-11.94446h0a8.63072,8.63072,0,0,0-14.09021,4.9129l-2.20929,11.41461-8.383,22.6341L543.0612,228.482Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#ffb8b8"
			/>
			<path
				d="M525.45683,236.02678,508.98216,200.9225a14.24259,14.24259,0,0,0-20.40241-5.74969h0a14.24249,14.24249,0,0,0-4.59235,19.36008l26.99152,46.26467a13.95267,13.95267,0,0,0,15.19611,6.39466h0a13.95266,13.95266,0,0,0,10.06048-9.33112l10.1789-31.894-13.41278-6.70636Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<path
				d="M556.54337,224.72881l-10.793-.905a6.42,6.42,0,0,1-5.85467-6.92638l4.15248-49.52174a6.42,6.42,0,0,1,6.92638-5.85467l10.793.905a6.42,6.42,0,0,1,5.85467,6.92638l-4.15248,49.52174A6.42,6.42,0,0,1,556.54337,224.72881Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#3f3d56"
			/>
			<path
				d="M549.4999,163.94091a3.45981,3.45981,0,0,0-3.73254,3.155l-4.15248,49.52174a3.45981,3.45981,0,0,0,3.155,3.73253l10.793.905a3.45981,3.45981,0,0,0,3.73254-3.155l4.15248-49.52174a3.45982,3.45982,0,0,0-3.155-3.73253Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#fa6761"
			/>
			<rect x="314.89656" y="150.85906" width="44.90328" height="44.90328" fill="#ffb8b8" />
			<circle cx="368.53287" cy="144.61325" r="2.40553" fill="#2f2e41" />
			<polygon
				points="408.613 340.53 420.589 340.529 426.287 294.335 408.611 294.336 408.613 340.53"
				fill="#ffb6b6"
			/>
			<path
				d="M573.262,453.065l18.932-1.13v8.11129l17.99918,12.43089a5.06661,5.06661,0,0,1-2.879,9.236H584.77485l-3.885-8.02331-1.51689,8.02331h-8.4982Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<polygon
				points="319.732 373.506 330.001 379.669 358.659 342.994 343.504 333.898 319.732 373.506"
				fill="#ffb6b6"
			/>
			<path
				d="M487.8448,485.35491l16.81389,8.77442-4.17444,6.95465,9.035,19.92148a5.06662,5.06662,0,0,1-7.22173,6.43728L482.97233,515.843l.79817-8.8786-5.42975,6.09855-7.28638-4.37357Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<path
				d="M246.53814,487.92863c-.1182,0-.23641-.00233-.35577-.007a8.64868,8.64868,0,0,1-6.95472-3.95541A97.70433,97.70433,0,0,0,218.65929,461.686c-4.82708-3.85817-9.87135-7.44558-14.74909-10.91537-12.58417-8.94786-24.43526-17.37516-31.01383-29.01954-14.34323-25.39136,2.54805-60.75771,26.67878-76.61836,27.066-17.78857,62.667-20.57885,97.107-20.94452q5.36888-.05676,10.74418-.05648h0A1024.40447,1024.40447,0,0,1,492.43149,341.048c20.29352,3.744,41.53558,8.33064,58.60562,19.46145,16.96872,11.065,27.2826,28.48966,26.27642,44.39282-1.46908,23.216-26.07961,40.56318-48.48972,45.09854-21.36026,4.31933-43.55026,1.40037-67.04223-1.69094-5.18635-.68184-10.54854-1.38756-15.85134-2.0007-57.6303-6.66125-165.89253,13.87043-170.47272,14.74792l-4.54409.86992-17.63136,22.689A8.46122,8.46122,0,0,1,246.53814,487.92863Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#fa6761"
			/>
			<path
				d="M503.17229,374.673H259.58865a5.29984,5.29984,0,0,1,0-10.59968H503.17229a5.29984,5.29984,0,0,1,0,10.59968Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#fff"
			/>
			<path
				d="M503.17229,407.20409H259.58865a5.29985,5.29985,0,1,1-.01106-10.59969h243.5947a5.29985,5.29985,0,0,1,0,10.59969Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#fff"
			/>
			<path
				d="M287.9747,439.73439H259.58865a5.29985,5.29985,0,0,1,0-10.59969H287.9747a5.29985,5.29985,0,0,1,0,10.59969Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#fff"
			/>
			<path
				d="M529.00812,301.35043l-48.00856-5.72919,1.38146,9.1021s-32.50914,26.1955-10.25457,55.09775l10.74392,10.47826-.98935,4.924h6.90034l.73982,5.94094,29.6804,24.56794,1.70384,5.02657h4.88784l-2.38862,5.71537-22.03538,51.24926,18.25586,8.512,33.62278-64.45655-8.61248-19.42694-30.98225-39.21279,47.98671,18.95429.81812,4.16067,4.41121-.83549-.85177,6.12364,9.36358,48.18111,19.83465-2.63232-2.03934-59.95109a18.59012,18.59012,0,0,0-10.12807-15.69436c-10.16724-5.22223-47.72552-19.73264-47.72552-19.73264Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<path
				d="M509.77825,196.33433l-9.03812-11.61773-12.89267-.559-1.28679,10.92665L480.17239,198.03a171.329,171.329,0,0,0-2.88591,74.22664l0,0h0a6.6092,6.6092,0,0,1,1.88,6.42l-.41415,1.65108.03832.06047a9.67173,9.67173,0,0,1,1.284,7.22047h0L468.396,308.36055c24.80059,10.10806,42.98157-8.74208,59.82466-33.44548l10.424-36.083Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#3f3d56"
			/>
			<polygon
				points="369.331 144.693 371.765 120.349 339.45 72.513 338.121 73.41 370.111 120.767 367.735 144.533 369.331 144.693"
				fill="#2f2e41"
			/>
			<circle cx="333.75187" cy="41.48196" r="23.83777" fill="#ffb8b8" />
			<path
				d="M504.43649,130.85255l-2.99132.09491-1.01589.03224-11.38374.3612c-10.09.32015-17.97759,9.78177-17.61742,21.13314-7.37112,7.88647-4.78227,17.3914,1.58582,27.5104a119.65546,119.65546,0,0,1,31.95872,7.47033l1.24192-6.18019,3.09,7.98926q3.83835,1.71162,7.62339,3.76769c-3.32258-13.49356-4.94267-27.00957-3.29153-37.60121l5.21289-.1654-.181-5.70474,1.9506,5.6486,9.45058-.29987v0A24.857,24.857,0,0,0,504.43649,130.85255Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<circle cx="316.23533" cy="11.22593" r="11.22582" fill="#2f2e41" />
			<path
				d="M557.60587,218.68621l8.20093-10.25113a8.63071,8.63071,0,0,0-1.12268-11.94446h0a8.63072,8.63072,0,0,0-14.09021,4.9129l-2.20929,11.41461-8.383,22.6341L550.0612,240.482Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#ffb8b8"
			/>
			<path
				d="M532.45683,248.02678,515.98216,212.9225a14.24259,14.24259,0,0,0-20.40241-5.74969h0a14.24249,14.24249,0,0,0-4.59235,19.36008l26.99152,46.26467a13.95267,13.95267,0,0,0,15.19611,6.39466h0a13.95266,13.95266,0,0,0,10.06048-9.33112l10.1789-31.894-13.41278-6.70636Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<path
				d="M878.93607,580.67463c-1.03763,1.66629-2.098,3.325-3.16592,4.96854q-2.97666,4.58982-6.11223,9.04336c0,.00758-.00758.00758-.01516.01516a21.38386,21.38386,0,0,1-4.01422,2.93114c-.0606.04544-.12874.07572-.18935.11358a14.79921,14.79921,0,0,1-3.378,1.35578c-.42414.106-.85586.21205-1.28.31809-1.52991.36354-3.04471.6741-4.552.94675-2.68118.47717-5.33208.818-7.96782,1.03006-.21209.02274-.42414.03786-.63624.053-10.83839.79526-21.389-.48475-32.40156-2.58271-5.43818-1.0301-10.98989-2.26467-16.73858-3.54468-1.87833-.42414-3.76425-.8407-5.67292-1.25726-.1439-.03028-.29539-.06819-.44688-.09847-2.72663-.59833-5.49869-1.18912-8.33894-1.77233-3.575-.73466-1.74247-.77739-5.57491-1.45149,0,0,8.84229,8.97914,10.31164,12.87219,1.636,4.78678,2.93114,8.57378.19694,16.4583l8.49142,16.66951L767.931,641.74891c-16.2696-31.88074-29.2724-52.87378-23.46381-65.23816.02271-.57562.06817-1.15882.14389-1.73445a19.84261,19.84261,0,0,1,7.55131-13.36051,19.10893,19.10893,0,0,1,14.23154-3.893l5.79413.74981,44.93653-2.18317,1.76475-7.1953.32568-.12116,10.35369-3.79459,35.80987-13.12576.09088-.03028,2.35551-.86344.28023.45442c.08335.13637.16665.2727.24238.409a70.19525,70.19525,0,0,1,5.6578,13.1333,206.17386,206.17386,0,0,1,5.94557,22.69931q.10228.49989.18177.90889l.02275.0833c.0227.12875.03786.212.04544.2348C881.02651,572.32048,880.45087,576.5165,878.93607,580.67463Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<line x1="617.11922" y1="550.99128" x2="616.40726" y2="550.99128" fill="#2f2e41" />
			<path
				d="M871.381,440.72334l-.25886-14.3538-22.74114-2.6462-2.38524,12.121-23.90059,22.46034c-16.993,20.77452-4.64483,35.1012-3.90562,37.2317l2.10154,26.19633,1.322,9.10178-5.1618,24.90869,65.1521,13.97632c2.08588-17.22131-.23693-33.2225-13.83868-46.10232l6.87306-21.38542,0,0a43.91161,43.91161,0,0,0,6.39183-18.421l.2472-2.2362C887.35037,462.65117,883.75257,456.28043,871.381,440.72334Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#e6e6e6"
			/>
			<path
				d="M903.28647,395.86136c.01516.37112.02274.74224.02274,1.10582a25.42557,25.42557,0,0,1-33.3105,24.13076l-4.67314-1.52238c-.03787-.01516-.08331-.0227-.12117-.03786l-7.70279-2.51457a5.26746,5.26746,0,0,1,.01516-.977c-11.48975-4.908,4.39291-21.033,8.339-33.14385.61349-1.88592-20.48009-.47717-19.48032-2.09043-.21968-.11363-.4393-.22721-.64382-.34084A31.21624,31.21624,0,0,1,880.2085,367.0347c.16661.03786.34084.09089.50745.13633,14.96623.37112,22.828,10.80054,22.828,24.34286A37.14181,37.14181,0,0,1,903.28647,395.86136Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<path
				d="M890.31978,401.19344a24.40084,24.40084,0,0,1-30.758,15.62517q-1.0452-.34081-2.045-.77251a24.38988,24.38988,0,0,1-13.58018-29.97792,24.1459,24.1459,0,0,1,2.43885-5.25636,24.3927,24.3927,0,0,1,43.94434,20.38162Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#a0616a"
			/>
			<path
				d="M865.321,419.57331a.84356.84356,0,0,1-.12-.04l-7.7-2.51c-.02-.33-14.16,9.03-14.12,8.7.22-2.53,16.15-14.87,18.53-17.74a14.75892,14.75892,0,0,0-6.48-23.44,1.18115,1.18115,0,0,1-.2-.07c-1.49-.52-2.92-1.05-4.26-1.58-.66-2.75-1-1.84-2.59-4.17.29,2.12-2.4,3.72-2.44,5.86-.55-.25-1.07-.51-1.57-.77-.22-.12-.43-.23-.64-.34a10.618,10.618,0,0,1-3.93-3.14c3.85-11.83,18.58-21.3,30.41-17.43l4.47,1.45a22.43394,22.43394,0,0,1,13.03,11.09,25.257,25.257,0,0,1,7.57995,9.49C905.071,404.58332,886.181,426.27332,865.321,419.57331Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<circle cx="730.04759" cy="257.64255" r="10.6036" fill="#2f2e41" />
			<path
				d="M1032.381,397.71333a70.32149,70.32149,0,0,1-37.12,47.73c-1.19007.59-2.4,1.14-3.62,1.65a23.98587,23.98587,0,0,0-4.07007-13.91,42.67769,42.67769,0,0,1,.16,10.04,42.24114,42.24114,0,0,1-.94995,5.69,70.48411,70.48411,0,0,1-24.34,3.51,23.64856,23.64856,0,0,0-3.87-10.24005,42.67768,42.67768,0,0,1,.16,10.04,68.90409,68.90409,0,0,1-23.93-6.41c-3.91-1.86-7.75-4.19-10.26995-7.71-3.35-4.69-3.91-10.88-3.09-16.58.82-5.71,2.87-11.16,4.3-16.74s2.24-11.55.56-17.06c-1.67-5.52-6.36-10.43-12.11-10.86-2.62-.2-5.25.52-7.87.28-2.62-.25-5.46-2-5.52-4.63l2.51-4.08c1.06-7.14,4.7-14.22,10.94-17.85,6.23-3.64,15.19-2.86,19.64,2.82,3.75,4.78,3.61,11.42,4.84,17.37,2.86,13.82,13.91,24.94,26.75,30.8,12.85,5.85,27.30993,7.08,41.43005,6.84,4.84-.09,9.76-.35,14.36-1.88C1025.881,404.99329,1030.18107,402.03333,1032.381,397.71333Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#2f2e41"
			/>
			<path
				d="M791.89859,461.44107l-4.15248-49.52174a6.42,6.42,0,0,1,5.85467-6.92638l10.793-.905a6.42,6.42,0,0,1,6.92638,5.85467l4.15248,49.52174a6.42,6.42,0,0,1-5.85467,6.92638l-10.793.905A6.42,6.42,0,0,1,791.89859,461.44107Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#3f3d56"
			/>
			<path
				d="M795.07547,407.41284a3.45984,3.45984,0,0,0-3.155,3.73254l4.15248,49.52174a3.45982,3.45982,0,0,0,3.73254,3.155l10.793-.905a3.45981,3.45981,0,0,0,3.155-3.73253L809.601,409.66284a3.45981,3.45981,0,0,0-3.73253-3.155Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#fa6761"
			/>
			<path
				d="M813.27322,478.433l10.50779-4.01-6.10282-23.35235-1.06928-11.57718a8.63071,8.63071,0,0,0-13.53509-6.28266h0a8.63071,8.63071,0,0,0-2.29877,11.77481l7.14662,11.01212Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#a0616a"
			/>
			<path
				d="M824.19565,470.25206l-14.01041,5.34662,6.97389,32.74451a13.95267,13.95267,0,0,0,9.08807,10.28057h0a13.95268,13.95268,0,0,0,15.75416-4.86l31.4358-43.36765a14.24248,14.24248,0,0,0-2.65465-19.71941h0a14.24245,14.24245,0,0,0-20.87111,3.70321l-19.86651,33.30236Z"
				transform="translate(-167.61898 -118.4431)"
				fill="#e6e6e6"
			/>
			<path
				d="M812.80073,487.8792l7.0817,28.75915,1.46918,1.78819a11.44829,11.44829,0,0,0,18.65624-1.36712l22.44348-37.31625-20.23484,27.99918A11.4483,11.4483,0,0,1,822.869,506.4851Z"
				transform="translate(-167.61898 -118.4431)"
				opacity="0.2"
			/>
		</svg>
	);
};
