import { sendInstantNotification } from '../../../../api/hermes/notifications';
import { OrgPerson } from '../../../../context/org-person/types';

const translations: { [key: string]: string } = {
	primaryEmail: 'Email',
	primaryPhone: 'Telefono',
	num: 'Numero civico',
	address: 'Indirizzo',
	cap: 'CAP',
	nation: 'Nazione',
	region: 'Regione',
	province: 'Provincia',
	town: 'Comune',
	birthdate: 'Data di nascita',
	name: 'Nome',
	surname: 'Cognome',
	medicalExaminationExpirationDate: 'Data prevista visita medica',
	medicalExaminationScheduledDate: 'Data scadenza visita medica',
	healthCardNumber: 'Tessera sanitaria',
	allergies: 'Allergie',
	identityDocumentNumber: 'Numero documento di identità',
	identityDocumentExpirationDate: 'Data scadenza documento di identità',
	identityDocumentReleaseDate: 'Data rilascio documento di identità',
	number: 'Numero documento di identità',
	releaseDate: 'Data rilascio documento di identità',
	attendedSchool: 'Scuola frequentata',
	schoolSpecialism: 'Indirizzo scolastico',
	iban: 'IBAN',
	avatar: 'Foto',
	parents: 'Responsabili legali',
};

const translationsMap = new Map<string, string>(Object.entries(translations));

export const notifyDataChange = (orgPerson: Pick<OrgPerson, 'orgPersonId' | 'organizationId'>, values: string[]) => {
	void sendInstantNotification(orgPerson.orgPersonId, orgPerson.organizationId, {
		ownerType: 'OrgPersonMetadata',
		occurrenceType: 'OrgPersonChangedDataFromApp',
		metadata: { changedProperties: values.map(value => translationsMap.get(value))?.join(', ') },
		origin: 'system',
	});
};
