import { Button } from '@/components/button';
import { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

type SignatureCanvasUploaderProps = {
	onCancel?: () => void;
	onChange?: (file: File | null) => void;
};

const createPNGFromBase64 = (base64String: string, fileName: string): File => {
	// Extract the Base64 string without the data URL part
	const base64Data = base64String.split(',')[1];

	// Convert Base64 string to a binary string
	const byteString = atob(base64Data);

	// Create an ArrayBuffer and a view
	const arrayBuffer = new ArrayBuffer(byteString.length);
	const uint8Array = new Uint8Array(arrayBuffer);

	// Fill the view with binary data
	for (let i = 0; i < byteString.length; i++) {
		uint8Array[i] = byteString.charCodeAt(i);
	}

	// Create a Blob with the image data and type
	const blob = new Blob([uint8Array], { type: 'image/png' });

	// Create a File from the Blob
	const file = new File([blob], fileName, { type: 'image/png' });

	return file;
};

export const SignatureCanvasUploader = (props: SignatureCanvasUploaderProps) => {
	const ref = useRef<any>();

	const onSave = async () => {
		const dataUrl = ref.current.getTrimmedCanvas().toDataURL('image/png');
		if (!dataUrl) {
			props.onChange?.(null);
			return;
		}

		props.onChange?.(createPNGFromBase64(dataUrl, 'image.png'));
	};

	return (
		<div>
			<SignatureCanvas
				canvasProps={{ className: 'border border-zinc-200 border-solid rounded w-full h-[240px]' }}
				ref={ref}
			/>

			<div className="mt-4 flex flex-col gap-2">
				<Button onClick={onSave}>Aggiungi firma</Button>
				<Button
					variant={'secondary'}
					onClick={() => {
						ref.current?.clear();
						props.onCancel?.();
					}}>
					Annulla
				</Button>
			</div>
		</div>
	);
};

function b64toBlob(b64Data: string, contentType: string, sliceSize?: number) {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

const fromDataUrlToFormData = (dataUrl: string) => {
	// Split the base64 string in data and contentType
	var block = dataUrl.split(';');
	// Get the content type of the image
	var contentType = block[0].split(':')[1]; // In this case "image/gif"
	// get the real base64 content of the file
	var realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

	// Convert it to a blob to upload
	var blob = b64toBlob(realData, contentType);

	// Create a FormData and append the file with "image" as parameter name
	let formData = new FormData();
	formData.append('file', blob);
	// var formDataToUpload = new FormData();
	// var formData = formDataToUpload.append('image', blob);
	return formData;
};
