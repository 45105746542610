import { apiClient } from '../client';

export type PepaFile = {
	_id: string;
	deleted: boolean;
	file_name: string;
	magic_url: string;
	mime: string;
	public_url: string;
};

const uploadFileToPepa = ({
	formData,
	config,
}: {
	formData: FormData;
	config: any;
}): Promise<{
	data: PepaFile;
}> => {
	return apiClient('pepa').post(`files`, formData, config);
};

export { uploadFileToPepa };
