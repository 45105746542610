import { useContext } from 'react';
import { ProfileContext } from './profiles-context';

export const useProfiles = () => {
	const context = useContext(ProfileContext);

	if (!context) {
		throw new Error('useProfiles must be used within ProfilesProvider');
	}

	return context;
};
