import { useI18n } from '@gle/providers.i18n';
import { InputDateField } from '../../core/inputs/DatePicker';
import { SelectField } from '../../core/inputs/Select';
import { CustomSelectField } from '../../core/inputs/custom-select';
import { CustomField } from '../../hooks/custom-input-fields/types';
import { CheckboxField, InputField } from '../inputs';

export const CustomInputField = (props: {
	field: CustomField;
	value: any;
	onChange: (value: any) => void;
	hideLabel?: boolean;
}) => {
	const { messages } = useI18n();

	const label = !props.hideLabel ? messages[props.field.labelKey] : '';

	const fieldKey = props.field.key.replace('metadata.', '');

	switch (props.field.type) {
		case 'BOOLEAN':
			return (
				<div style={{ alignSelf: 'center' }}>
					<CheckboxField name={fieldKey} value={props.value} label={label} onChange={props.onChange} />
				</div>
			);

		case 'NUMBER':
			return (
				<InputField
					name={fieldKey}
					type={'number'}
					min={props.field.min}
					max={props.field.max}
					value={props.value}
					label={label}
					onChange={props.onChange}
				/>
			);

		case 'SELECT':
			return (
				<SelectField
					name={fieldKey}
					label={label}
					options={props.field.options}
					value={props.value}
					isClearable
					onChange={(newValue: any) => props.onChange(newValue?.value)}
				/>
			);

		case 'CUSTOM-SELECT':
			return (
				<CustomSelectField
					name={fieldKey}
					label={label}
					optionsKey={props.field.optionsKey}
					value={props.value}
					isClearable
					onChange={(newValue: any) => props.onChange(newValue?.value)}
				/>
			);

		case 'TEXT':
			return (
				<InputField
					name={fieldKey}
					type="text"
					label={label}
					value={props.value || ''}
					onChange={props.onChange}
				/>
			);

		case 'DATE':
			return <InputDateField name={fieldKey} label={label} value={props.value} onChange={props.onChange} />;

		default:
			return <></>;
	}
};
