import { ErrorMessage, FieldConfig, useField } from 'formik';
import styled from 'styled-components';

import { InputDate, InputDateProps } from '@gle/base-ui.inputs.input-date';
import { Text } from '@gle/base-ui.typography.text';
import { useUpdatedFieldProps } from '../use-updated-field-props';

export type InputDateFieldProps = FieldConfig<any> &
	Omit<InputDateProps, 'onBlur' | 'onFocus'> & {
		hint?: string | undefined;
	};

const Wrapper = styled.div`
	width: 100%;
	height: 70px;
	display: flex;
	flex-direction: column;
	gap: 5px;

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);
	}

	&.error {
		label {
			color: var(--danger-color);
		}
		input {
			border-color: var(--danger-color);
		}
	}
`;

const InputDateField = ({ onChange, ...props }: InputDateFieldProps) => {
	const [field, meta, helpers] = useField(props);
	const updatedProps = useUpdatedFieldProps<InputDateFieldProps>(props, 'input-date');

	return (
		<Wrapper className={`${meta.touched && meta.error ? 'error' : ''} `}>
			<InputDate
				{...field}
				{...updatedProps}
				onChange={(value, formats) => {
					helpers.setValue(value, true);
					onChange?.(value, formats);
				}}
			/>

			{meta.error ? (
				<ErrorMessage name={props.name} component="div" className={`form-field-error`} />
			) : (
				<>
					{props.hint && (
						<Text color="gray" size="xs">
							{props.hint}
						</Text>
					)}
				</>
			)}
		</Wrapper>
	);
};

export default InputDateField;
