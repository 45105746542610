import { Button } from '@/components/button';
import { Text } from '@gle/base-ui.typography.text';
import { useI18n } from '@gle/providers.i18n';
import { DownloadIcon } from 'lucide-react';
import moment from 'moment';
import { Post } from '../../../../api/club/posts-feed';
import { Divider } from '../../../../components/divider';
import { TextWithLinksRenderer } from '../../../../components/text-with-links-renderer';
import Reactions from './post-reactions';

export const PostItem = ({ post }: { post: Post }) => {
	const { messages } = useI18n();
	const reaction = post.reactions.length ? post.reactions[0].reaction : '';
	const attachment = post.attachment;

	return (
		<div className={'overflow-hidden rounded-md border border-solid border-gray-200 p-0'}>
			<div className={'flex justify-between bg-slate-100 p-2'}>
				<div className={'flex flex-col'}>
					<p className="text-sm font-medium text-black">{post.authorName}</p>
					<p className="text-xs font-light text-slate-500">{moment(post.createdAt).fromNow()}</p>
				</div>
			</div>
			<div className="p-2">
				<div className={'space mb-3 mt-2'}>
					{post.content.html ? (
						<div
							className="html-content-preview"
							dangerouslySetInnerHTML={{ __html: post.content.html }}></div>
					) : (
						<TextWithLinksRenderer text={post.content.data} />
					)}
				</div>
				{attachment && (
					<div className={'mt-5 flex flex-row items-center gap-1.5'}>
						<a href={attachment?.url} target={'_blank'}>
							<Button size="sm" variant={'secondary'}>
								<DownloadIcon size={18} className="mr-2" />
								{messages['download']}
							</Button>
						</a>
						<Text size="s" color="gray">
							{attachment.fileName}
						</Text>
					</div>
				)}
				<Divider style={{ marginBlock: 10 }} />
				<Reactions reaction={reaction} postId={post._id} type={post.type} />
			</div>
		</div>
	);
};
