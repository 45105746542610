export const ProfileImage = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 837.16296 682.58438">
			<path
				d="M686.53634,740.28385c12.1195,8.122,25.928,49.18,25.928,49.18s-43.22554,2.81814-55.34146-5.29978a26.41318,26.41318,0,1,1,29.41342-43.88023Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#f1f1f1"
			/>
			<path
				d="M712.95643,789.15479l-.67712.53994C687.5782,758.707,650.30363,748.00387,649.93,747.89936l.23314-.83406C650.53935,747.17037,688.07592,757.94211,712.95643,789.15479Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#fff"
			/>
			<path
				d="M751.35233,723.90385c-.77266,20.17711-39.12933,66.25383-39.12933,66.25383s-34.69967-48.88913-33.92928-69.059a36.55622,36.55622,0,1,1,73.05861,2.80521Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#f1f1f1"
			/>
			<path
				d="M712.94439,790.51313l-1.12779-.40593c18.583-51.60164,4.13625-103.294,3.98818-103.81014l1.15206-.33076C717.10612,686.48586,731.66231,738.53675,712.94439,790.51313Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#fff"
			/>
			<path
				d="M613.62492,609.09746H284.88786a22.11531,22.11531,0,0,1-22.09021-22.09034V211.326a22.11531,22.11531,0,0,1,22.09021-22.09033H613.62492A22.11533,22.11533,0,0,1,635.71525,211.326V587.00712A22.11533,22.11533,0,0,1,613.62492,609.09746Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e6e6e6"
			/>
			<path
				d="M598.26384,590.68632H300.24894a19.63606,19.63606,0,0,1-19.61376-19.61377v-343.812a19.63606,19.63606,0,0,1,19.61376-19.61377h298.0149a19.636,19.636,0,0,1,19.61377,19.61377v343.812A19.636,19.636,0,0,1,598.26384,590.68632Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#fff"
			/>
			<path
				d="M586.40816,256.58077H436.06832a4.90308,4.90308,0,1,1,0-9.80615H586.40816a4.90308,4.90308,0,1,1,0,9.80615Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M586.40816,275.95529H436.06832a4.90332,4.90332,0,0,1,0-9.80664H586.40816a4.90332,4.90332,0,0,1,0,9.80664Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M501.237,295.3298h-65.1687a4.90332,4.90332,0,0,1,0-9.80664H501.237a4.90332,4.90332,0,1,1,0,9.80664Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M568.27889,373.58077H330.93905a4.90308,4.90308,0,1,1,0-9.80615H568.27889a4.90308,4.90308,0,1,1,0,9.80615Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M568.27889,392.95529H330.93905a4.90332,4.90332,0,1,1,0-9.80664H568.27889a4.90332,4.90332,0,0,1,0,9.80664Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M568.27889,436.93462H330.93905a4.90308,4.90308,0,1,1,0-9.80616H568.27889a4.90308,4.90308,0,1,1,0,9.80616Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M568.27889,456.30913H330.93905a4.90332,4.90332,0,1,1,0-9.80664H568.27889a4.90332,4.90332,0,1,1,0,9.80664Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M568.27889,500.28846H330.93905a4.90308,4.90308,0,1,1,0-9.80616H568.27889a4.90308,4.90308,0,1,1,0,9.80616Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M568.27889,519.663H330.93905a4.90332,4.90332,0,0,1,0-9.80664H568.27889a4.90332,4.90332,0,1,1,0,9.80664Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M568.237,573.3298h-65.1687a4.90332,4.90332,0,0,1,0-9.80664H568.237a4.90332,4.90332,0,0,1,0,9.80664Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#fa6762"
			/>
			<path
				d="M394.45162,271.62619a46.44905,46.44905,0,0,1-10.10986,28.99,45.77,45.77,0,0,1-7.2002,7.26,46.4361,46.4361,0,0,1-25.48,10.22c-1.27.11-2.55029.16-3.84033.16a46.39674,46.39674,0,0,1-7.62988-.62994c-.88965-.14-1.75977-.31006-2.62989-.51a43.81,43.81,0,0,1-4.48-1.25,46.40822,46.40822,0,0,1-13.26025-6.96c-1.06983-.8-2.09961-1.65-3.09961-2.55a42.02129,42.02129,0,0,1-3.47022-3.46c-.29-.32-.58007-.65-.85986-.98a46.61748,46.61748,0,1,1,82.06006-30.29Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<circle cx="164.77978" cy="159.72326" r="19.10891" fill="#9e616a" />
			<path
				d="M367.47164,259.11618a7.7307,7.7307,0,0,0-1.37012-4.8l-.68994-.98c-2.49023-4.8-6.6001-9.12-9.60986-12.28-2.26026-2.37-4.52-3.51-6.69043-3.4a5.99035,5.99035,0,0,0-4.08985,2.04,14.71588,14.71588,0,0,0-14,7.99c-9.1499,4.82995-6.41992,19.09-6.39013,19.24l2.21045,3.71.25-.47c0-.01.02978-.05.06982-.13.43018-.77,2.77-5.05,4.3501-7.76a7.67049,7.67049,0,0,1,2.25-2.32,11.20166,11.20166,0,0,1,4.96-1.87l4.85986-.63-1.83984,2.76H354.9814l5.08985-.6c.51025,2.09,4.55029,10.2,5.04,11.18l.04.08.27.55,1.51025-4.15A16.0467,16.0467,0,0,0,367.47164,259.11618Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#2f2e41"
			/>
			<path
				d="M319.96148,309.00615c.82-1.08,5.07-6.12,14.85-8.79l5.18-5.77,20.77-3.31,10.57,5.84,13.01,3.64a45.75853,45.75853,0,0,1-7.2,7.26,46.43694,46.43694,0,0,1-25.48,10.22c-1.27.11-2.55.16-3.84.16a46.39209,46.39209,0,0,1-7.63-.63c-.89-.14-1.76-.31-2.63-.51a43.76988,43.76988,0,0,1-4.48-1.25,46.17761,46.17761,0,0,1-13.12-6.86c-.05-.03-.09-.07-.14-.1Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#fa6762"
			/>
			<path
				d="M714.12713,132.09646H184.38577a2.97049,2.97049,0,0,1-2.96725-2.96725V111.67506a2.97049,2.97049,0,0,1,2.96725-2.96725H714.12713a2.97049,2.97049,0,0,1,2.96725,2.96725v17.45415A2.97049,2.97049,0,0,1,714.12713,132.09646Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#fa6762"
			/>
			<circle
				id="a85e5c17-ea9f-4706-8082-8afb2e9db2dc"
				data-name="Ellipse 90"
				cx="17.22021"
				cy="10.84298"
				r="4.21275"
				fill="#fff"
			/>
			<circle
				id="b621e56c-df3f-4a6b-a567-05f014ec6f14"
				data-name="Ellipse 91"
				cx="33.21043"
				cy="10.84298"
				r="4.21275"
				fill="#fff"
			/>
			<circle
				id="f2c0e2e9-064c-4994-bbee-183d3c3fcd77"
				data-name="Ellipse 92"
				cx="49.2014"
				cy="10.84298"
				r="4.21275"
				fill="#fff"
			/>
			<path
				d="M698.46012,116.65121H680.04678a1.364,1.364,0,0,1,0-2.7279h18.41334a1.364,1.364,0,1,1,0,2.7279Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#fff"
			/>
			<path
				d="M698.46012,121.766H680.04678a1.36395,1.36395,0,0,1,0-2.7279h18.41334a1.364,1.364,0,1,1,0,2.7279Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#fff"
			/>
			<path
				d="M698.46012,126.88085H680.04678a1.364,1.364,0,0,1,0-2.72791h18.41334a1.364,1.364,0,0,1,0,2.72791Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#fff"
			/>
			<path
				d="M843.89527,578.07976a8.56621,8.56621,0,0,1,1.70826-13.02376l-7.02037-29.621,14.85847,5.42843,4.3887,27.29637a8.61268,8.61268,0,0,1-13.93506,9.92Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#9e616a"
			/>
			<path
				d="M808.1641,453.95234l5.96312,15.33372s-2.55562,15.33373,1.70375,18.74122,2.55562,3.4075,2.55562,5.96312a13.10381,13.10381,0,0,0,5.11124,9.37061c3.40749,2.55562,18.74122,54.51991,18.74122,54.51991h15.33372a48.04248,48.04248,0,0,1,0-11.92623c.85188-5.96312-2.55562-21.29684-4.25937-23.85246s0-5.96312,0-11.07436-3.40749-7.66686-3.40749-16.1856-10.22248-74.96487-9.37061-82.63174-8.51874-14.48185-8.51874-14.48185h-8.51873Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<polygon
				points="665.96 669.223 654.628 669.223 649.237 625.511 665.962 625.512 665.96 669.223"
				fill="#9e616a"
			/>
			<path
				d="M850.26912,788.91611l-36.54109-.00135v-.46219A14.22356,14.22356,0,0,1,827.95084,774.23h.00091l6.67471-5.06379L847.08,774.23076l3.18981.00013Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#2f2e41"
			/>
			<polygon
				points="562.289 668.899 551.647 665.005 561.6 622.102 577.308 627.849 562.289 668.899"
				fill="#9e616a"
			/>
			<path
				d="M742.648,788.91611l-34.3166-12.55477.15879-.43406a14.22357,14.22357,0,0,1,18.24325-8.47077l.00085.00031,8.0081-2.46252,9.95566,9.03466,2.99562,1.096Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#2f2e41"
			/>
			<path
				d="M822.41119,573.53008s-7.66687,37.48244,5.11124,34.07494,7.66686-35.77869,7.66686-35.77869Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#a0616a"
			/>
			<path
				d="M766.18753,542.43669s-10.22248,13.63-10.22248,21.29684-3.4075,75.81675-3.4075,75.81675S726.69279,741.03374,731.804,746.145s23.00059,10.22249,25.55621,7.66687,25.01289-107.44658,25.01289-107.44658L803.67,589.28974l19.59309,81.77986,1.70375,86.89111s21.29684,5.11124,25.55621,1.70375,5.11124-86.03923,0-115.00294.85187-95.40984.85187-95.40984Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#2f2e41"
			/>
			<circle cx="618.93206" cy="226.29766" r="25.55621" fill="#9e616a" />
			<path
				d="M767.89128,397.61818s7.35831-10.96385,21.84016-25.44571c7.66686-2.55562,20.95087-1.06611,25.5562,0,21.29684,28.11183,22.63717,26.24377,28.1808,41.95076,1.43753,4.073,2.1563,7.98632,1.5174,11.18084-2.55562,12.77811,5.53718,116.28075,6.389,119.68824s6.815,7.66686.85187,7.66686-96.26171-3.40749-94.558-5.96311S767.89128,397.61818,767.89128,397.61818Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<polygon
				points="582.572 436.864 608.196 380.486 595.384 440.445 582.572 436.864"
				opacity="0.1"
				style={{ isolation: 'isolate' }}
			/>
			<path
				d="M792.61905,358.68155a43.48878,43.48878,0,0,0,6.57469-6.03411,14.59365,14.59365,0,0,0,3.56154-8.0453,8.86094,8.86094,0,0,0-3.37856-7.85457c-2.43412-1.7196-6.14589-1.61954-8.16629.57133.97608-3.15529,1.86756-6.87695-.10939-9.52275-1.79926-2.408-5.15566-2.81718-8.05488-3.61112a23.342,23.342,0,0,1-13.49393-10.05242l4.92348,1.01886-.54909-3.50988,4.08006,1.41865.03567-4.8885,4.2109,2.356a26.88448,26.88448,0,0,1,18.93963-7.05664,20.94173,20.94173,0,0,1,10.0423,2.75692,14.70109,14.70109,0,0,1,6.54346,7.95111c2.16731-2.24978,6.03162-2.07581,8.61372-.31754s4.09867,4.6997,5.14023,7.64485a32.99606,32.99606,0,0,1,1.388,17.65322,26.27915,26.27915,0,0,1-9.02589,15.08357,29.30482,29.30482,0,0,1-14.70023,5.84754,54.85691,54.85691,0,0,1-15.9502-.682"
				transform="translate(-181.41852 -108.70781)"
				fill="#2f2e41"
			/>
			<path
				d="M756.01257,577.96926a8.56625,8.56625,0,0,0-1.70826-13.02377l7.02037-29.621-14.85847,5.42844-4.3887,27.29636a8.61268,8.61268,0,0,0,13.93506,9.92Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#9e616a"
			/>
			<path
				d="M776.41,397.61818h-8.51873s-9.37061,6.815-8.51874,14.48185-9.37061,74.113-9.37061,82.63174-3.40749,11.07436-3.40749,16.1856,1.70374,8.51873,0,11.07436-5.11125,17.88934-4.25937,23.85246a48.04248,48.04248,0,0,1,0,11.92623h15.33372s15.33373-51.96429,18.74122-54.51991a13.10381,13.10381,0,0,0,5.11124-9.37061c0-2.55562-1.70374-2.55562,2.55562-5.96312s1.70375-18.74122,1.70375-18.74122l5.96312-15.33372Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#e4e4e4"
			/>
			<path
				d="M1017.39079,791.29219h-467.294a1.19069,1.19069,0,1,1,0-2.38137h467.294a1.19069,1.19069,0,1,1,0,2.38137Z"
				transform="translate(-181.41852 -108.70781)"
				fill="#cacaca"
			/>
		</svg>
	);
};
