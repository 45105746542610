import { Heading } from '@/components/heading';
import { useConan } from '@golee/gle-conan-tracker';
import { useNavigate } from 'react-router-dom';
import { NavigateBack } from '../../../../../components';
import { useOrgPerson } from '../../../../../context/org-person';
import { notifyDataChange } from '../../utils/notify-data-changed';
import { GeneralForm } from './general-form';

export const ProfileGeneralPage = () => {
	const navigate = useNavigate();
	const { trackEvent } = useConan();
	const { orgPerson, refresh: refreshOrgPersonContext } = useOrgPerson();

	const notifyChangesToOrganization = (updatedValues: { [key: string]: boolean | undefined }) => {
		const updatedValuesAsArray = Object.keys(updatedValues).filter(key => !!updatedValues[key]);

		if (updatedValuesAsArray.length) {
			notifyDataChange(orgPerson, updatedValuesAsArray as string[]);
			void trackEvent('family.orgPerson-changed-data-from-app', {
				values: updatedValuesAsArray.join(', '),
				section: 'name_surname',
			});
		}
	};

	return (
		<div className="pb-4">
			<NavigateBack to={'/profile'} className="mb-4" />
			<Heading level={2} className="mb-4">
				Generali
			</Heading>

			<GeneralForm
				orgPersonId={orgPerson.orgPersonId}
				season={orgPerson.season}
				initialValues={{
					name: orgPerson.name,
					surname: orgPerson.surname,
					fiscalCode: orgPerson.fiscalCode,
					birthdate: orgPerson.personMetadata.dates?.birthdate
						? new Date(orgPerson.personMetadata.dates.birthdate)
						: undefined,
					avatar: orgPerson.personMetadata.avatar,
				}}
				onComplete={updatedValues => {
					refreshOrgPersonContext();
					notifyChangesToOrganization(updatedValues);
					navigate('/profile');
				}}
				onCancel={() => {
					navigate('/profile');
				}}
			/>
		</div>
	);
};
