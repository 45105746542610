import { Button } from '@/components/button';
import { LogOutIcon } from 'lucide-react';
import { useAuth } from '../../context/auth';

export const Logout = () => {
	const { logout } = useAuth();

	return (
		<Button variant={'destructive'} onClick={logout}>
			<LogOutIcon size={18} className="mr-2" />
			{'Logout'}
		</Button>
	);
};
