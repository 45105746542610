import { Button } from '@/components/button';
import { useState } from 'react';
import {
	Drawer,
	DrawerContent,
	DrawerDescription,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from '../../@/components/drawer';

type ConfirmSignatureButtonProps = {
	disabled: boolean;
	isLoading: boolean;
	onConfirm: () => void;
};

export const ConfirmSignatureButton = (props: ConfirmSignatureButtonProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<Drawer
			open={isOpen}
			onOpenChange={open => {
				if (props.isLoading) {
					return;
				}
				setIsOpen(open);
			}}
			dismissible={!props.isLoading}>
			<DrawerTrigger>
				<Button disabled={props.disabled}>Salva</Button>
			</DrawerTrigger>
			<DrawerContent>
				<DrawerHeader>
					<DrawerTitle>Sei sicuro?</DrawerTitle>
					<DrawerDescription>Questa operazione non è reversibile.</DrawerDescription>
				</DrawerHeader>

				<div className="mt-4 flex flex-col gap-2 p-4">
					<Button onClick={props.onConfirm} isLoading={props.isLoading}>
						Conferma
					</Button>
					<Button
						variant={'secondary'}
						onClick={() => setIsOpen(false)}
						disabled={props.isLoading}
						className="button-xl">
						Annulla
					</Button>
				</div>
			</DrawerContent>
		</Drawer>
	);
};
