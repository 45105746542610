const scripts = [
	{
		name: 'pdfjsLib',
		src: 'https://unpkg.com/pdfjs-dist@2.3.200/build/pdf.min.js',
	},
	{
		name: 'PDFLib',
		src: 'https://unpkg.com/pdf-lib@1.4.0/dist/pdf-lib.min.js',
	},
];

const assets: { [key: string]: any } = {};

export function getAsset(name: string) {
	if (assets[name]) return assets[name];
	const script = scripts.find(s => s.name === name);
	if (!script) throw new Error(`Script ${name} not exists.`);
	return prepareAsset(script);
}

export function prepareAsset({ name, src }: { name: string; src: string }) {
	if (assets[name]) return assets[name];
	assets[name] = new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = src;
		script.onload = () => {
			resolve((window as any)[name]);
			console.log(`${name} is loaded.`);
		};
		script.onerror = () => {
			reject(`The script ${name} didn't load correctly.`);
			alert(`Some scripts did not load correctly. Please reload and try again.`);
		};
		document.body.appendChild(script);
	});
	return assets[name];
}

export default function prepareAssets() {
	scripts.forEach(prepareAsset);
}
