import { Spinner } from '@/components/spinner';

// TODO: need to be renamed
export const LoadingLayout = () => {
	return (
		<div className="flex items-center justify-center pt-40">
			<Spinner />
		</div>
	);
};
