import { Heading } from '@/components/heading';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/tabs';
import { PrivateDocumentList } from './list/private-document-list';
import { PublicDocumentList } from './list/public-document-list';

export const DocumentsPage = () => {
	return (
		<div className="pb-5">
			<Heading className="mb-5">Documenti</Heading>
			<Tabs defaultValue="private">
				<TabsList className="grid w-full grid-cols-2">
					<TabsTrigger value="private">Personali</TabsTrigger>
					<TabsTrigger value="shared">Condivisi con tutti</TabsTrigger>
				</TabsList>
				<TabsContent value="private">
					<PrivateDocumentList />
				</TabsContent>
				<TabsContent value="shared">
					<PublicDocumentList />
				</TabsContent>
			</Tabs>
		</div>
	);
};
